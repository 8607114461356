import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Upload, Loader2, Download, RefreshCw, AlertCircle, Image as ImageIcon, X,  Plus, Brain, Scan, Shirt, Calendar, Sparkles, ChevronDown, InfoIcon } from 'lucide-react';

// API Configuration
const VIRTUAL_TRY_ON_API = {
  url: 'https://virtual-try-on4.p.rapidapi.com/tryon',
  headers: {
    'X-RapidAPI-Key': '5753d4ff57msh87c3a47a3c08cf7p1410ecjsn0579d844e535',
    'X-RapidAPI-Host': 'virtual-try-on4.p.rapidapi.com',
    'Content-Type': 'application/json'
  }
};

// Image Upload Card Component
const ImageUploadCard = ({ title, image, onImageChange, isProcessing }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="backdrop-blur-lg bg-white/30 rounded-2xl p-4 shadow-xl border border-white/20"
  >
    <div className="flex justify-between items-center mb-3">
      <h3 className="text-lg font-medium text-gray-800">{title}</h3>
      {!image && (
        <span className="text-xs text-blue-600 bg-blue-50 px-2 py-1 rounded-full">
          Or use quick select below ↓
        </span>
      )}
    </div>
    <div 
      className={`
        relative border-2 border-dashed rounded-xl p-3 transition-all
        ${image ? 'border-green-400' : 'border-gray-300 hover:border-blue-400'}
        ${isProcessing ? 'opacity-50' : ''}
      `}
    >
      {!image ? (
        <div className="flex flex-col items-center justify-center py-6">
          <Upload className="w-10 h-10 text-gray-400 mb-2" />
          <p className="text-sm text-gray-500">Click to upload</p>
          <p className="text-xs text-gray-400 mt-1">Max size: 5MB</p>
        </div>
      ) : (
        <div className="relative group">
          <img
            src={URL.createObjectURL(image)}
            alt="Preview"
            className="w-full h-48 object-contain rounded-lg"
          />
          <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg flex items-center justify-center">
            <button 
              onClick={(e) => {
                e.preventDefault();
                onImageChange(null);
              }}
              className="bg-white/20 backdrop-blur-sm text-white px-3 py-1 rounded-lg hover:bg-white/30 transition-colors text-sm"
            >
              Change
            </button>
          </div>
        </div>
      )}
      <input
        type="file"
        accept="image/jpeg,image/png,image/webp"
        onChange={(e) => onImageChange(e.target.files[0])}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        disabled={isProcessing}
      />
    </div>
  </motion.div>
);

// Clean version of uploadToImgBB
const uploadToImgBB = async (file) => {
  try {
    const formData = new FormData();
    formData.append('key', '4940a19adc85bb7ae6634fd76aa04bbd');
    formData.append('image', file);

    console.log('Uploading file:', {
      name: file.name,
      type: file.type,
      size: file.size
    });

    const response = await fetch('https://api.imgbb.com/1/upload', {
      method: 'POST',
      body: formData
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    if (!data.success) {
      console.error('ImgBB Error:', data.error);
      throw new Error(data.error?.message || 'Upload failed');
    }

    console.log('Upload successful:', data.data.url);
    return data.data.url;

  } catch (err) {
    console.error('Upload Error:', err);
    throw new Error(`Failed to upload image: ${err.message}`);
  }
};

// Beta Badge Component
const BetaBadge = () => (
  <div className="flex flex-col items-center justify-center gap-1 mb-4">
    <div className="flex items-center gap-2">
      <span className="text-sm text-gray-600">Currently in</span>
      <span className="px-2 py-0.5 text-xs bg-purple-100 text-purple-800 rounded-full font-medium">
        BETA
      </span>
    </div>
    <div className="flex items-center gap-1 text-sm text-blue-600">
      <InfoIcon className="w-4 h-4" />
      <span>Currently supports upper body clothing only</span>
    </div>
  </div>
);

// Update EXAMPLE_IMAGES constant with better descriptions
const EXAMPLE_IMAGES = {
  person: [
    { url: '/Person1.jpg', caption: 'Professional pose' },
    { url: '/Person2.jpg', caption: 'Casual stance' },
  ],
  clothing: [
    { url: '/Tshirt.jpg', caption: 'White T-shirt' },
    { url: '/Tshirt2.jpg', caption: 'Orange T-shirt' },
    { url: '/Jacket2.jpg', caption: 'Leather jacket' }
  ]
};

// Add cooldown timer component
const CooldownTimer = ({ timeLeft, isActive }) => {
  if (!isActive) return null;
  
  return (
    <div className="text-sm text-gray-600 flex items-center gap-2">
      <Loader2 className="w-4 h-4 animate-spin" />
      <span>Please wait {timeLeft} seconds before next try</span>
    </div>
  );
};

// Update ExampleImages component with better design
const ExampleImages = ({ type, images, onSelect }) => (
  <div className="bg-white/20 backdrop-blur-sm rounded-lg p-3 mt-2">
    <h4 className="text-xs font-medium text-gray-600 mb-2">Quick select {type}:</h4>
    <div className="grid grid-cols-4 gap-2">
      {images.map((img, idx) => (
        <button
          key={idx}
          onClick={() => onSelect(img.url)}
          className="group relative aspect-square overflow-hidden rounded-lg border border-gray-200 
                   hover:border-blue-400 hover:shadow-lg transition-all duration-200 
                   focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <img
            src={img.url}
            alt={`Example ${type} ${idx + 1}`}
            className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-200"
          />
          {/* Hover Overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent 
                        opacity-0 group-hover:opacity-100 transition-opacity duration-200 
                        flex flex-col justify-between p-2">
            <div className="self-end">
              <span className="bg-white/90 rounded-full p-1">
                <Plus className="w-4 h-4 text-blue-600" />
              </span>
            </div>
            <span className="text-[10px] text-white font-medium text-center">
              {img.caption}
            </span>
          </div>
          
          {/* Quick Select Label */}
          <div className="absolute top-0 left-0 m-1.5">
            <span className="text-[10px] bg-white/90 text-gray-600 px-2 py-0.5 rounded-full">
              Quick Select
            </span>
          </div>
        </button>
      ))}
    </div>
  </div>
);

// Feature Teaser Component
const FeatureTeaser = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Helper function to get background color based on feature type
  const getFeatureBackground = (type) => {
    const backgrounds = {
      ai: 'bg-blue-500/10 group-hover:bg-blue-500/20',
      wardrobe: 'bg-purple-500/10 group-hover:bg-purple-500/20',
      challenges: 'bg-pink-500/10 group-hover:bg-pink-500/20',
      store: 'bg-green-500/10 group-hover:bg-green-500/20',
      generator: 'bg-orange-500/10 group-hover:bg-orange-500/20'
    };
    return backgrounds[type] || 'bg-gray-500/10 group-hover:bg-gray-500/20';
  };

  return (
    <div className="max-w-7xl mx-auto">
      <div className="text-center mb-6">
        <div 
          className="flex flex-col items-center cursor-pointer group"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-bold text-gray-800 hover:text-blue-600 transition-colors">
              See What's Coming Soon
            </h2>
            <motion.div
              animate={{ rotate: isExpanded ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <ChevronDown className="w-5 h-5 text-gray-500 group-hover:text-blue-600" />
            </motion.div>
            <span className="px-2 py-0.5 text-xs bg-purple-100 text-purple-800 rounded-full">
              New
            </span>
          </div>
          {!isExpanded && (
            <p className="text-sm text-gray-500 mt-1">Click to explore upcoming features</p>
          )}
        </div>
      </div>

      {/* Collapsible Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto"
          >
            {/* AI Style Assistant */}
            <motion.div 
              className="group hover:shadow-lg transition-all duration-300 rounded-xl
                         bg-white/5 backdrop-blur-sm border border-white/10
                         hover:border-blue-200/30 hover:bg-white/10"
              whileHover={{ y: -2 }}
            >
              <div className="flex items-start gap-4 p-5">
                <div className={`p-3 rounded-xl transition-colors ${getFeatureBackground('ai')}`}>
                  <Brain className="w-6 h-6 text-blue-600" />
                </div>
                <div>
                  <div className="flex items-center gap-2 mb-1">
                    <h3 className="font-semibold text-gray-800">AI Style Assistant</h3>
                    <span className="px-2 py-0.5 rounded-full text-xs font-medium bg-amber-400/20 text-amber-700">
                      PRO
                    </span>
                  </div>
                  <p className="text-sm text-gray-600">Get personalized style recommendations powered by AI</p>
                </div>
              </div>
            </motion.div>

            {/* Virtual Wardrobe */}
            <motion.div 
              className="group hover:shadow-lg transition-all duration-300 rounded-xl
                         bg-white/5 backdrop-blur-sm border border-white/10
                         hover:border-purple-200/30 hover:bg-white/10"
              whileHover={{ y: -2 }}
            >
              <div className="flex items-start gap-4 p-5">
                <div className={`p-3 rounded-xl transition-colors ${getFeatureBackground('wardrobe')}`}>
                  <Shirt className="w-6 h-6 text-purple-600" />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-800 mb-1">Virtual Wardrobe</h3>
                  <p className="text-sm text-gray-600">Save and organize your favorite outfits</p>
                </div>
              </div>
            </motion.div>

            {/* Style Challenges */}
            <motion.div 
              className="group hover:shadow-lg transition-all duration-300 rounded-xl
                         bg-white/5 backdrop-blur-sm border border-white/10
                         hover:border-pink-200/30 hover:bg-white/10"
              whileHover={{ y: -2 }}
            >
              <div className="flex items-start gap-4 p-5">
                <div className={`p-3 rounded-xl transition-colors ${getFeatureBackground('challenges')}`}>
                  <Calendar className="w-6 h-6 text-pink-600" />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-800 mb-1">Style Challenges</h3>
                  <p className="text-sm text-gray-600">Join weekly challenges and win rewards</p>
                </div>
              </div>
            </motion.div>

            {/* In-Store Try-On */}
            <motion.div 
              className="group hover:shadow-lg transition-all duration-300 rounded-xl
                         bg-white/5 backdrop-blur-sm border border-white/10
                         hover:border-green-200/30 hover:bg-white/10"
              whileHover={{ y: -2 }}
            >
              <div className="flex items-start gap-4 p-5">
                <div className={`p-3 rounded-xl transition-colors ${getFeatureBackground('store')}`}>
                  <Scan className="w-6 h-6 text-green-600" />
                </div>
                <div>
                  <h3 className="font-semibold text-gray-800 mb-1">In-Store Try-On</h3>
                  <p className="text-sm text-gray-600">Instantly try on clothes in partner stores</p>
                </div>
              </div>
            </motion.div>

            {/* AI Outfit Generator Banner */}
            <motion.div 
              className="md:col-span-2 mt-6"
              whileHover={{ y: -2 }}
            >
              <div className="rounded-2xl bg-gradient-to-r from-amber-50 to-orange-50 
                            border border-orange-100 p-6 group hover:shadow-lg 
                            transition-all duration-300">
                <div className="flex items-center gap-3 mb-2">
                  <div className={`p-2 rounded-lg transition-colors ${getFeatureBackground('generator')}`}>
                    <Sparkles className="w-6 h-6 text-orange-600" />
                  </div>
                  <h3 className="font-semibold text-gray-800">AI Outfit Generator Coming Soon</h3>
                  <span className="px-2 py-0.5 rounded-full text-xs font-medium bg-amber-400/20 text-amber-700">
                    PRO
                  </span>
                </div>
                <p className="text-sm text-gray-600">
                  We're developing an AI that will automatically generate perfect outfits from our partner's collections. 
                  Get early access with our Pro subscription!
                </p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// Floating AI Assistant Button
const AIAssistantButton = () => (
  <motion.div 
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    className="fixed bottom-6 right-6 z-50"
  >
    <button 
      className="group flex items-center gap-2 px-4 py-3 bg-gradient-to-r from-blue-600 to-purple-600 
                 text-white rounded-full shadow-lg hover:shadow-xl transition-all duration-300"
    >
      <Brain className="w-5 h-5" />
      <span className="font-medium">Style AI</span>
      <span className="absolute -top-12 right-0 pointer-events-none bg-white text-gray-700 px-3 py-2 
                     rounded-lg text-sm shadow-lg opacity-0 group-hover:opacity-100 transition-all duration-300">
        Coming Soon
      </span>
    </button>
  </motion.div>
);

// Main Component
const VirtualTryOn = () => {
  const [personImage, setPersonImage] = useState(null);
  const [clothingImage, setClothingImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [cooldownTime, setCooldownTime] = useState(0);
  const [lastProcessingTime, setLastProcessingTime] = useState(0);

  // Add cooldown timer effect
  useEffect(() => {
    if (cooldownTime <= 0) return;

    const timer = setInterval(() => {
      setCooldownTime(prev => Math.max(0, prev - 1));
    }, 1000);

    return () => clearInterval(timer);
  }, [cooldownTime]);

  // Clean version of processImages
  const processImages = async () => {
    const now = Date.now();
    const timeSinceLastProcess = (now - lastProcessingTime) / 1000;
    
    if (timeSinceLastProcess < 180) { // 3 minutes cooldown
      setError(`Please wait ${Math.ceil(180 - timeSinceLastProcess)} seconds before trying again`);
      return;
    }

    if (!personImage || !clothingImage) {
      setError('Please upload both a person image and a clothing image');
      return;
    }

    setIsProcessing(true);
    setError(null);

    try {
      const clothingUrl = await uploadToImgBB(clothingImage);
      const personUrl = await uploadToImgBB(personImage);

      console.log('Uploaded URLs:', {
        clothing: clothingUrl,
        person: personUrl
      });

      const tryOnResponse = await fetch(VIRTUAL_TRY_ON_API.url, {
        method: 'POST',
        headers: {
          'X-RapidAPI-Key': '5753d4ff57msh87c3a47a3c08cf7p1410ecjsn0579d844e535',
          'X-RapidAPI-Host': 'virtual-try-on4.p.rapidapi.com',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          garm: clothingUrl,
          human: personUrl,
          category: 'upper_body'
        })
      });

      // First get the raw response text
      const responseText = await tryOnResponse.text();
      console.log('Raw API Response:', responseText);

      // Try to parse as JSON
      let result;
      try {
        result = JSON.parse(responseText);
      } catch (e) {
        console.error('Failed to parse API response:', e);
        if (responseText.includes('<!DOCTYPE')) {
          throw new Error(' service is temporarily unavailable. Please try again later.');
        } else {
          throw new Error('Invalid response ');
        }
      }

      if (result.error) {
        throw new Error(`API Error: ${result.error}`);
      }

      if (result.img) {
        setResultImage(result.img);
      } else {
        throw new Error('No output image in response');
      }

      setLastProcessingTime(Date.now());
      setCooldownTime(180); // Set 3 minute cooldown
    } catch (err) {
      console.error('Virtual Try-On Error:', err);
      if (err.message.includes('503')) {
        setError('Service is temporarily unavailable. Please try again later.');
      } else {
        setError(err.message || 'Failed to process images. Please try again.');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const resetAll = () => {
    setPersonImage(null);
    setClothingImage(null);
    setResultImage(null);
    setError(null);
  };

  // Define handleImageChange as a function that takes a file and updates the appropriate state
  const handleImageChange = (file, setImageState) => {
    try {
      if (file) {
        // Validate file
        if (!file.type.startsWith('image/')) {
          throw new Error('Please upload an image file');
        }
        
        if (file.size > 5 * 1024 * 1024) {
          throw new Error('Image size should be less than 5MB');
        }

        setImageState(file);
        setError(null);
      } else {
        setImageState(null);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleExampleSelect = async (url, setImageState) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], 'example-image.jpg', { type: 'image/jpeg' });
      handleImageChange(file, setImageState);
    } catch (err) {
      setError('Failed to load example image');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-purple-50 to-pink-50">
      {/* Feature Teaser at top */}
      <div className="pt-6 px-4">
        <FeatureTeaser />
      </div>

      {/* Main content - removed redundant header */}
      <div className="py-8 px-4">
        <div className="max-w-7xl mx-auto">
          {/* Beta Badge */}
          <BetaBadge />
          
          {/* Upload Grid */}
          <div className="grid grid-cols-1 md:grid-cols-5 gap-6 mb-8">
            {/* Upload Section */}
            <div className="md:col-span-2 space-y-4">
              <ImageUploadCard
                title="Person Image"
                image={personImage}
                onImageChange={(file) => handleImageChange(file, setPersonImage)}
                isProcessing={isProcessing}
              />
              
              <ExampleImages 
                type="person" 
                images={EXAMPLE_IMAGES.person}
                onSelect={(url) => handleExampleSelect(url, setPersonImage)}
              />

              <ImageUploadCard
                title="Clothing Image"
                image={clothingImage}
                onImageChange={(file) => handleImageChange(file, setClothingImage)}
                isProcessing={isProcessing}
              />
              
              <ExampleImages 
                type="clothing" 
                images={EXAMPLE_IMAGES.clothing}
                onSelect={(url) => handleExampleSelect(url, setClothingImage)}
              />
            </div>

            {/* Result Section */}
            <div className="md:col-span-3">
              {resultImage ? (
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="bg-white/30 backdrop-blur-lg rounded-2xl p-6 shadow-xl"
                >
                  <h3 className="text-lg font-medium text-gray-800 mb-4">Result</h3>
                  <div className="relative group aspect-[3/4]">
                    <div className="flex justify-center w-full h-full">
                      <img
                        src={resultImage}
                        alt="Try-on result"
                        className="rounded-lg shadow-md max-w-full max-h-full object-contain"
                      />
                    </div>
                    <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg flex items-center justify-center">
                      <a 
                        href={resultImage}
                        download="virtual-tryon-result.png"
                        className="bg-white/20 backdrop-blur-sm text-white px-4 py-2 rounded-lg hover:bg-white/30 transition-colors flex items-center gap-2"
                      >
                        <Download className="w-5 h-5" />
                        Download Result
                      </a>
                    </div>
                  </div>
                </motion.div>
              ) : (
                <div className="aspect-[3/4] flex items-center justify-center bg-white/30 backdrop-blur-lg rounded-2xl p-6">
                  <div className="text-center">
                    <ImageIcon className="w-12 h-12 text-gray-400 mx-auto mb-3" />
                    <p className="text-gray-500">Upload or select example images to see the result</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Update Actions section */}
          <div className="flex flex-col items-center gap-4 mb-6">
            <div className="flex justify-center gap-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={processImages}
                disabled={isProcessing || !personImage || !clothingImage || cooldownTime > 0}
                className="px-6 py-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-xl
                       disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
              >
                {isProcessing ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Processing...
                  </>
                ) : (
                  <>
                    <ImageIcon className="w-5 h-5" />
                    Generate Try-On
                  </>
                )}
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={resetAll}
                className="px-6 py-2 bg-white/30 backdrop-blur-sm text-gray-700 rounded-xl
                       hover:bg-white/50 transition-all flex items-center gap-2"
              >
                <RefreshCw className="w-5 h-5" />
                Reset
              </motion.button>
            </div>
            <CooldownTimer timeLeft={cooldownTime} isActive={cooldownTime > 0} />
          </div>

          {/* Error Display */}
          {error && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="max-w-2xl mx-auto mb-6 p-4 bg-red-50/50 backdrop-blur-sm border border-red-100 rounded-xl flex items-center gap-3"
            >
              <AlertCircle className="h-5 w-5 text-red-600" />
              <p className="text-sm text-red-700">{error}</p>
              <button
                onClick={() => setError(null)}
                className="ml-auto text-red-600 hover:text-red-800"
              >
                <X className="h-5 w-5" />
              </button>
            </motion.div>
          )}

          {/* Floating AI Assistant Button */}
          <AIAssistantButton />
        </div>
      </div>
    </div>
  );
};

export default VirtualTryOn;