import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Services from './Services';
import LeadGenerationFlow from './StartFree';
import TextToImage from './pages/TextToImage';
import VirtualTryOn from './pages/VirtualTryOn';
import { ThemeProvider } from './components/ThemeProvider';

const AppContent = () => {
  const location = useLocation();
  const isStartFreePage = location.pathname === '/start-free';
  

  return (
<div className="min-h-screen bg-white dark:bg-[#0A0F29] transition-colors duration-200">      <main>
      {!isStartFreePage && <Header />}
      <Routes>
        <Route path="/start-free" element={<LeadGenerationFlow />} />
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/pages/TextToImage" element={<TextToImage />} />
        <Route path="/pages/VirtualTryOn" element={<VirtualTryOn />} />
      </Routes>
      </main>
    </div>
  );
};

const App = () => {
  return (
      <ThemeProvider>
        <Router>
          <AppContent />
        </Router>
      </ThemeProvider>
  );
};

export default App;