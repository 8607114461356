import React from 'react';
import { 
  Bot, 
  BrainCircuit, 
  //BarChart3, 
  //Globe2, 
  MessageSquare, 
  Settings2, 
  ArrowRight,
  CheckCircle2
} from 'lucide-react';

const ServicesPage = () => {
  const mainServices = [
    {
      icon: <Bot className="w-8 h-8 text-blue-500" />,
      title: "AI ჩეთბოთები",
      englishTitle: "AI Chatbots",
      description: "Custom chatbots that understand Georgian language nuances and business context",
      features: [
        "24/7 Customer Support",
        "Bilingual Communication",
        "Custom Knowledge Base",
        "Easy Integration"
      ]
    },
    {
      icon: <BrainCircuit className="w-8 h-8 text-blue-500" />,
      title: "ბიზნეს ანალიტიკა",
      englishTitle: "Business Analytics",
      description: "Data-driven insights to optimize your business operations and decision making",
      features: [
        "Sales Prediction",
        "Customer Behavior Analysis",
        "Market Trends",
        "Performance Metrics"
      ]
    },
    {
      icon: <MessageSquare className="w-8 h-8 text-blue-500" />,
      title: "ტექსტის ანალიზი",
      englishTitle: "NLP Solutions",
      description: "Natural Language Processing for Georgian and English content analysis",
      features: [
        "Sentiment Analysis",
        "Content Categorization",
        "Text Summarization",
        "Language Detection"
      ]
    },
    {
        icon: <Settings2 className="w-8 h-8 text-blue-500" />,
        title: "ბიზნეს ავტომატიზაცია",
        englishTitle: "Business Automation",
        description: "Automate repetitive tasks and streamline your business processes with AI",
        features: [
          "Workflow Automation",
          "Document Processing",
          "Data Entry Automation",
          "Report Generation"
        ]
    },
  ];

  const processList = [
    {
      title: "Consultation",
      description: "We understand your specific needs and challenges"
    },
    {
      title: "Custom Solution",
      description: "Develop tailored AI solution for your business"
    },
    {
      title: "Implementation",
      description: "Seamless integration with your existing systems"
    },
    {
      title: "Support",
      description: "Ongoing maintenance and optimization"
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="bg-gradient-to-b from-blue-50 to-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              AI სერვისები
              <span className="block text-blue-600 mt-2">
                Our AI Services
              </span>
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Transforming Georgian businesses with cutting-edge AI solutions
            </p>
          </div>
        </div>
      </section>

      {/* Main Services Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-3 gap-8">
            {mainServices.map((service, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-200 overflow-hidden">
                <div className="p-8">
                  <div className="inline-block p-3 bg-blue-50 rounded-lg mb-4">
                    {service.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-900">
                    {service.title}
                    <span className="block text-blue-600 text-lg mt-1">
                      {service.englishTitle}
                    </span>
                  </h3>
                  <p className="text-gray-600 mb-6">
                    {service.description}
                  </p>
                  <div className="space-y-3">
                    {service.features.map((feature, i) => (
                      <div key={i} className="flex items-center text-gray-600">
                        <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                        <span>{feature}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="p-4 bg-gray-50 border-t">
                  <button className="w-full py-2 px-4 bg-white text-blue-600 rounded-lg border border-blue-600 hover:bg-blue-50 transition-colors duration-200 flex items-center justify-center">
                    Learn More
                    <ArrowRight className="ml-2 w-4 h-4" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">
            How We Work
          </h2>
          <div className="grid md:grid-cols-4 gap-8">
            {processList.map((process, index) => (
              <div key={index} className="relative">
                <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-200">
                  <div className="w-10 h-10 bg-blue-600 text-white rounded-full flex items-center justify-center mb-4">
                    {index + 1}
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{process.title}</h3>
                  <p className="text-gray-600">{process.description}</p>
                </div>
                {index < processList.length - 1 && (
                  <div className="hidden md:block absolute top-1/2 right-0 w-full h-0.5 bg-blue-200 transform translate-x-1/2">
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-blue-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Ready to Start Your AI Journey?
          </h2>
          <p className="text-blue-100 mb-8 max-w-2xl mx-auto">
            Let's discuss how our AI solutions can help your business grow
          </p>
          <div className="flex justify-center space-x-4">
            <button className="px-8 py-3 bg-white text-blue-600 rounded-lg font-medium hover:bg-blue-50 transition-colors duration-200 shadow-lg">
              Schedule Consultation
            </button>
            <button className="px-8 py-3 bg-transparent text-white border-2 border-white rounded-lg font-medium hover:bg-blue-700 transition-colors duration-200">
              View Pricing
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesPage;