import { useCallback, useRef } from 'react';

export const useSound = () => {
  const audioContext = useRef(null);

  const playMessageSentSound = useCallback(() => {
    if (!audioContext.current) {
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
    }

    const ctx = audioContext.current;
    const oscillator = ctx.createOscillator();
    const gainNode = ctx.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(ctx.destination);

    const now = ctx.currentTime;
    const duration = 0.2; // Shorter duration for the message sent sound

    // Simulating the iPhone "whoosh" sound for message sent
    oscillator.frequency.setValueAtTime(900, now); // Start frequency
    oscillator.frequency.exponentialRampToValueAtTime(200, now + duration); // Swoosh down

    // Adjust the volume envelope to mimic the sound's fade
    gainNode.gain.setValueAtTime(0, now);
    gainNode.gain.linearRampToValueAtTime(0.4, now + 0.05); // Fade in quickly
    gainNode.gain.linearRampToValueAtTime(0, now + duration); // Fade out quickly

    // Play the sound
    oscillator.start(now);
    oscillator.stop(now + duration);
  }, []);

  const playMessageReceivedSound = useCallback(() => {
    if (!audioContext.current) {
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
    }

    const ctx = audioContext.current;
    const oscillator = ctx.createOscillator();
    const gainNode = ctx.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(ctx.destination);

    const now = ctx.currentTime;
    const duration = 0.1; // Shorter for the message received sound

    // Simulating the iPhone "ding" sound for message received
    oscillator.frequency.setValueAtTime(600, now); // Higher-pitched 'ding' sound
    oscillator.frequency.exponentialRampToValueAtTime(500, now + duration); // Slight modulation

    // Volume envelope for subtle 'ding' sound
    gainNode.gain.setValueAtTime(0, now);
    gainNode.gain.linearRampToValueAtTime(0.3, now + 0.02); // Quick fade in
    gainNode.gain.linearRampToValueAtTime(0, now + duration); // Quick fade out

    // Play the sound
    oscillator.start(now);
    oscillator.stop(now + duration);
  }, []);

  return { playMessageSentSound, playMessageReceivedSound };
};

// Check for Web Audio API support
if (typeof AudioContext === 'undefined' && typeof webkitAudioContext === 'undefined') {
  console.warn('Web Audio API is not supported in this browser');
}

// Check for secure context
if (window.isSecureContext === false) {
  console.warn('AudioContext may not work because the page is not in a secure context (HTTPS)');
}