import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Globe, Menu, X, Sun, Moon } from 'lucide-react';
import { useTheme } from './components/ThemeProvider';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [language, setLanguage] = React.useState('ge');
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const { isDark, toggleDark } = useTheme();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const menuItems = [
    { name: 'მთავარი', englishName: 'Home', path: '/' },
    { name: 'სერვისები', englishName: 'Services', path: '/services' },
    { name: 'პროექტები', englishName: 'Projects', path: '/projects' },
    { name: 'კონტაქტი', englishName: 'Contact', path: '/contact' },
  ];

  const aiItems = [
    { name: 'ტექსტი-სურათში', englishName: 'Text to Image', path: '/pages/TextToImage' },
    { name: 'ვირტუალური სტუმრება', englishName: 'Virtual Try On', path: '/pages/VirtualTryOn', beta: true },
  ];

  const toggleLanguage = () => {
    setLanguage(language === 'ge' ? 'en' : 'ge');
  };

  const handleStartFree = () => {
    navigate('/start-free');
  };

  return (
    <header className="sticky top-0 bg-white/90 dark:bg-[#0A0F29] backdrop-blur-md shadow-sm z-50">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="text-xl font-semibold bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent flex items-center">
              AZRI AI
              <span className="text-xs ml-2 text-gray-600 dark:text-gray-400">AI for Georgian SMEs</span>
            </Link>
            <div className="hidden md:block ml-2 w-8 h-8 bg-contain bg-no-repeat" style={{backgroundImage: "url('/georgian-pattern.svg')"}}></div>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-6">
            {menuItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200"
              >
                {language === 'ge' ? item.name : item.englishName}
              </Link>
            ))}

            {/* AI Dropdown Menu */}
            <div className="relative">
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200"
              >
                {language === 'ge' ? 'AI-ების ცდა' : "Try AI's"}
              </button>
              {isDropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 shadow-lg rounded-lg py-1 z-20 border border-gray-200 dark:border-gray-700"
                >
                  {aiItems.map((item) => (
                    <Link
                      key={item.name}
                      to={item.path}
                      className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      {language === 'ge' ? item.name : item.englishName}
                      {item.beta && <span className="ml-1 text-xs text-red-500">beta</span>}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {/* Theme Toggle */}
            <button
              onClick={toggleDark}
              className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200"
              aria-label="Toggle dark mode"
            >
              {isDark ? (
                <Sun className="w-5 h-5" />
              ) : (
                <Moon className="w-5 h-5" />
              )}
            </button>

            {/* Language Toggle */}
            <button
              onClick={toggleLanguage}
              className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200 flex items-center"
            >
              <Globe className="w-4 h-4 mr-1" />
              {language === 'ge' ? 'EN' : 'ქართ'}
            </button>

            {/* Start Free Button */}
            <button 
              onClick={handleStartFree}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-200 flex items-center"
            >
              {language === 'ge' ? 'დაიწყეთ უფასოდ' : 'Start Free'}
              <span className="ml-1 text-xs">🇬🇪</span>
            </button>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center space-x-2">
            <button
              onClick={toggleDark}
              className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium"
              aria-label="Toggle dark mode"
            >
              {isDark ? (
                <Sun className="w-5 h-5" />
              ) : (
                <Moon className="w-5 h-5" />
              )}
            </button>
            <button
              onClick={toggleLanguage}
              className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 px-3 py-2 rounded-md text-sm font-medium"
            >
              <Globe className="w-5 h-5" />
            </button>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded-md text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 focus:outline-none"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden bg-white dark:bg-gray-800">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {menuItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className="block px-3 py-2 rounded-md text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 text-base font-medium"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {language === 'ge' ? item.name : item.englishName}
                </Link>
              ))}
              {aiItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className="block px-3 py-2 rounded-md text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 text-base font-medium"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {language === 'ge' ? item.name : item.englishName}
                  {item.beta && <span className="ml-1 text-xs text-red-500">beta</span>}
                </Link>
              ))}
              <button 
                onClick={handleStartFree}
                className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center mt-2"
              >
                {language === 'ge' ? 'დაიწყეთ უფასოდ' : 'Start Free'}
                <span className="ml-1 text-xs">🇬🇪</span>
              </button>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;