import React, { useState, useEffect } from 'react';
import { 
  Loader2, RefreshCw, Download, 
  Sparkles, Trash2, Copy, 
  Clock, Image, Maximize2, History, 
  ExternalLink,
  Globe, Zap
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';



// FLUX API configuration
const API_URL = "https://api-inference.huggingface.co/models/black-forest-labs/FLUX.1-dev";

// Add these constants at the top
const TIERS = {
  FREE: {
    name: "Free Plan",
    badge: "5 Daily",
    badgeColor: "blue",
    features: [
      { icon: Clock, text: "5 generations per day" },
      { icon: Image, text: "2 styles available" },
      { icon: Maximize2, text: "512x512 resolution" },
      { icon: History, text: "30m history retention" },
      { icon: Globe, text: "English only" }
    ]
  },
  PRO: {
    name: "Pro Plan",
    badge: "500 Monthly",
    badgeColor: "yellow",
    features: [
      { icon: Clock, text: "500 generations per month" },
      { icon: Image, text: "5 premium styles" },
      { icon: Maximize2, text: "1024x1024 HD resolution" },
      { icon: History, text: "Unlimited history" },
      { icon: Globe, text: "English & Georgian" }
    ]
  }
};

// Update STYLES to mark pro features
const STYLES = [
  { 
    id: 'product', 
    name: 'Product Photography', 
    icon: '📸',
    prompt: 'professional product photography, studio lighting, commercial quality,  background, marketing material masterpiece, best quality, highly detailed, 4k, sharp focus, with shadow',
    isPro: false,
    useCase: 'E-commerce product shots'
  },
  { 
    id: 'real-estate', 
    name: 'Real Estate', 
    icon: '🏠',
    prompt: 'architectural photography, real estate, professional interior design, natural lighting, wide angle masterpiece, best quality, highly detailed, 4k, ',
    isPro: false,
    useCase: 'Property listings'
  },
  { 
    id: 'social-media', 
    name: 'Social Media', 
    icon: '📱',
    prompt: 'social media content, engaging, vibrant, modern, trendy, instagram worthy masterpiece, best quality, highly detailed, 4k, sharp focus',
    isPro: false,
    useCase: 'Social media marketing'
  },
  { 
    id: 'corporate', 
    name: 'Corporate', 
    icon: '👔',
    prompt: 'professional business setting, corporate environment, office space, professional attire masterpiece, best quality, highly detailed, 4k, sharp focus',
    isPro: false,
    useCase: 'Company websites & materials'
  },
  { 
    id: 'food', 
    name: 'Restaurant & Food', 
    icon: '🍽️',
    prompt: 'professional food photography, appetizing, restaurant quality, menu presentation masterpiece, best quality, highly detailed, 4k,',
    isPro: false,
    useCase: 'Menu items & food marketing'
  },
  { 
    id: 'realistic', 
    name: 'Realistic', 
    icon: '🎨',
    prompt: 'highly detailed, 4k, realistic, high resolution sharp focus masterpiece best quality, intricate detail masterpiece, best quality, highly detailed, 4k,',
    isPro: false
  },
  { 
    id: 'anime', 
    name: 'Anime Style', 
    icon: '📷',
    prompt: 'anime style, manga, studio ghibli, detailed anime illustration masterpiece, best quality, highly detailed, 4k, sharp focus',
    isPro: false
  },
  { 
    id: 'artistic', 
    name: 'Artistic', 
    icon: '🎮',
    prompt: 'artistic, oil painting, detailed brushstrokes, vibrant colors ',
    isPro: false
  },
  { 
    id: 'digital-art', 
    name: 'Digital Art', 
    icon: '🐉',
    prompt: 'digital art, concept art, detailed, trending on artstation ',
    isPro: false
  },
  { 
    id: 'photographic', 
    name: 'Photographic', 
    icon: '🖼️',
    prompt: 'professional photography, 8k, high resolution, detailed photograph masterpiece, best quality, highly detailed, 4k, sharp focus',
    isPro: false
  }
];

// Add this component for Pro badges
const ProBadge = () => (
  <span className="ml-2 text-xs bg-gradient-to-r from-yellow-200 to-yellow-300 px-2 py-0.5 rounded-full font-medium text-yellow-800">
    PRO
  </span>
);

// Add this for locked features
const LockedFeature = () => (
  <div className="absolute inset-0 bg-black/5 backdrop-blur-[1px] flex items-center justify-center rounded-lg">
    <div className="flex items-center gap-2 bg-white/90 px-3 py-1.5 rounded-full shadow-sm">
      <span className="text-sm">🔒</span>
      <span className="text-sm font-medium">Pro Feature</span>
    </div>
  </div>
);

// Update the tier selection UI
const TierCard = ({ tier, isPro, onSelect }) => {
  const bgClass = isPro 
    ? "bg-gradient-to-br from-white to-blue-50 border-2 border-blue-200" 
    : "bg-white";

  return (
    <div className={`${bgClass} rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all relative`}>
      {isPro && (
        <div className="absolute -top-4 -right-4 bg-gradient-to-r from-yellow-400 to-yellow-500 text-white px-4 py-1 rounded-full text-sm font-medium shadow-lg">
          PRO
        </div>
      )}

      <div className="flex justify-between items-center mb-8">
        <h2 className="text-2xl font-bold">{tier.name}</h2>
        <span className={`
          px-4 py-1.5 rounded-full text-sm font-medium
          ${isPro 
            ? 'bg-gradient-to-r from-yellow-200 to-yellow-300 text-yellow-800' 
            : 'bg-blue-100 text-blue-700'
          }
        `}>
          {tier.badge}
        </span>
      </div>

      <div className="mb-8 relative group">
        <div className="text-sm text-gray-600 hover:text-gray-800 flex items-center gap-2 cursor-pointer">
          <Image className="w-4 h-4" />
          <span>Hover to see example</span>
        </div>
        
        {/* Hover panel for examples */}
        <div className="opacity-0 invisible group-hover:opacity-100 group-hover:visible absolute left-0 right-0 mt-2 p-4 bg-gray-50 rounded-xl shadow-lg z-10 transition-all duration-200">
          {isPro ? (
            <div>
              <div className="text-xs text-gray-500 mb-1">Prompt:</div>
              <div className="font-medium text-gray-700 mb-2">პატარა საყვარელი მაიმუნი</div>
              <img 
                src="/პატარა საყვარელი მაიმუნი.png" 
                alt="Generated monkey example"
                className="w-full h-48 object-cover rounded-lg"
              />
            </div>
          ) : (
            <div>
              <div className="text-xs text-gray-500 mb-1">Prompt:</div>
              <div className="font-medium text-gray-700 mb-2">Lion cubs playing</div>
              <img 
                src="/lion cubs playing.png" 
                alt="Generated lion cubs"
                className="w-full h-48 object-cover rounded-lg"
              />
            </div>
          )}
        </div>
      </div>

      <ul className="space-y-6 mb-8">
        {tier.features.map((feature, index) => (
          <li key={index} className="flex items-start gap-3">
            <feature.icon className={`w-5 h-5 mt-1 ${isPro ? 'text-yellow-500' : 'text-blue-500'}`} />
            <span className="text-gray-600">{feature.text}</span>
          </li>
        ))}
      </ul>

      {isPro ? (
        <a 
          href="/contact"
          className="w-full py-3.5 bg-gradient-to-r from-blue-600 to-indigo-600 text-white rounded-xl hover:from-blue-700 hover:to-indigo-700 transition-colors flex items-center justify-center gap-2 font-medium group"
        >
          <ExternalLink className="w-5 h-5 group-hover:rotate-12 transition-transform" />
          Contact for Pro
        </a>
      ) : (
        <button 
          onClick={() => onSelect('FREE')}
          className="w-full py-3.5 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 font-medium group"
        >
          <Zap className="w-5 h-5 group-hover:translate-x-0.5 transition-transform" />
          Start Free
        </button>
      )}
    </div>
  );
};

const TierSelection = ({ onSelect }) => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-12">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            AI Image Generator
          </h1>
          <p className="text-xl text-gray-600">
            Choose your plan to start generating images
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <TierCard tier={TIERS.FREE} isPro={false} onSelect={onSelect} />
          <TierCard tier={TIERS.PRO} isPro={true} onSelect={onSelect} />
        </div>
      </div>
    </div>
  );
};

const TextToImage = () => {
  const [prompt, setPrompt] = useState('');
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);
  const [ipLimitCount, setIpLimitCount] = useState(0);
  const [isPro, setIsPro] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);

  // Keep these variables
  const maxGenerations = isPro ? TIERS.PRO.maxGenerations : 10;  // Using 10 for free tier
  const remainingGenerations = maxGenerations - ipLimitCount;

  useEffect(() => {
    const savedCount = localStorage.getItem('generationCount');
    if (savedCount) {
      setIpLimitCount(parseInt(savedCount));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('generationCount', ipLimitCount.toString());
  }, [ipLimitCount]);

  useEffect(() => {
    const proKey = localStorage.getItem('proAccessKey');
    setIsPro(Boolean(proKey));
  }, []);

  const generateImage = async () => {
    try {
      // Check if prompt and style are selected
      if (!prompt.trim()) {
        throw new Error("Please enter a description for your image");
      }
      
      if (!selectedStyle) {
        throw new Error("Please select a style for your image");
      }

      // Check generation limit
      if (ipLimitCount >= maxGenerations) {
        throw new Error("You've reached the limit of 5 images. Please try again later.");
      }

      setIsGenerating(true);
      setError(null);

      // Combine user prompt with style prompt
      const enhancedPrompt = `${prompt}, ${selectedStyle.prompt}`;
      console.log('Enhanced prompt:', enhancedPrompt);

      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          "Authorization": "Bearer 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",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          inputs: enhancedPrompt,
          parameters: {
            guidance_scale: 100,
            num_inference_steps: 100,
            seed: Math.floor(Math.random() * 1000000),
            width: 1024,                  // Optional: increase if you want larger images
            height: 1024,                  // Optional: increase if you want larger images
          }
        })
      });

      console.log('Response status:', response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.log('Error details:', errorText);
        throw new Error(`Request failed: ${errorText}`);
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setGeneratedImage(imageUrl);

      setHistory(prev => [...prev, {
        id: Date.now(),
        prompt: prompt,
        imageUrl,
        style: selectedStyle,
        timestamp: new Date().toISOString()
      }]);

    } catch (err) {
      console.error('Full error:', err);
      setError(err.message);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `generated-image-${Date.now()}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.error('Download failed:', err);
    }
  };

  if (!selectedTier) {
    return <TierSelection onSelect={setSelectedTier} />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Error Display - Move it closer to where errors happen */}
        {error && (
          <div className="mb-4">
            <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg flex items-center">
              <span className="mr-2">⚠️</span>
              {error}
              <button 
                onClick={() => setError(null)} 
                className="ml-auto text-red-400 hover:text-red-600"
              >
                ✕
              </button>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Main Content */}
          <div className="lg:col-span-2">
            <div className="bg-white rounded-2xl shadow-xl p-6">
              {/* Style Selector - More compact */}
              <div className="mb-4">
                <div className="flex justify-between items-center mb-3">
                  <h3 className="text-lg font-semibold text-gray-800">Style</h3>
                  {!isPro && <ProBadge />}
                </div>
                
                <div className="flex flex-wrap gap-2">
                  {STYLES.map((style) => (
                    <div key={style.id} className="relative">
                      <button
                        onClick={() => (!style.isPro || isPro) && setSelectedStyle(style)}
                        className={`
                          flex items-center px-3 py-1.5 rounded-lg transition-all text-sm
                          ${selectedStyle?.id === style.id
                            ? 'bg-blue-100 text-blue-700'
                            : 'bg-gray-50 hover:bg-gray-100'
                          }
                          ${style.isPro && !isPro ? 'opacity-50' : ''}
                        `}
                      >
                        <span className="mr-1.5">{style.icon}</span>
                        {style.name}
                        {style.isPro && <ProBadge />}
                      </button>
                      {style.isPro && !isPro && <LockedFeature />}
                    </div>
                  ))}
                </div>
              </div>

              {/* Prompt Input - Cleaner layout */}
              <div className="mb-4">
                <div className="relative">
                  <textarea
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    placeholder="Describe what you want to create..."
                    className="w-full p-4 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none bg-gray-50"
                    rows={3}
                  />
                  <button
                    onClick={generateImage}
                    disabled={isGenerating || !prompt.trim() || !selectedStyle}
                    className="absolute bottom-3 right-3 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                  >
                    {isGenerating ? (
                      <>
                        <Loader2 className="w-4 h-4 animate-spin" />
                        Generating...
                      </>
                    ) : (
                      <>
                        <Sparkles className="w-4 h-4" />
                        Generate
                      </>
                    )}
                  </button>
                </div>
                <div className="text-sm text-gray-500 mt-2">
                  {remainingGenerations} generations remaining today
                </div>
              </div>

              {/* Output Display */}
              <div className="relative aspect-square w-full bg-gray-100 rounded-lg overflow-hidden">
                <AnimatePresence>
                  {isGenerating && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="absolute inset-0 flex items-center justify-center bg-gray-100"
                    >
                      <Loader2 className="w-12 h-12 text-blue-600 animate-spin" />
                    </motion.div>
                  )}
                </AnimatePresence>

                {generatedImage && !isGenerating && (
                  <div className="relative group">
                    <img
                      src={generatedImage}
                      alt="Generated"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-4 right-4 flex gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                      <button
                        onClick={() => handleDownload(generatedImage)}
                        className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-colors"
                      >
                        <Download className="w-5 h-5 text-gray-700" />
                      </button>
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(prompt);
                        }}
                        className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-colors"
                      >
                        <Copy className="w-5 h-5 text-gray-700" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* History Sidebar - Move Pro upgrade here */}
          <div className="lg:col-span-1 space-y-4">
            {!isPro && (
              <div className="bg-white rounded-2xl shadow-xl p-6 bg-gradient-to-br from-yellow-50 to-orange-50">
                <div className="flex items-start gap-3">
                  <div className="text-yellow-500 text-xl">✨</div>
                  <div>
                    <h4 className="font-medium text-yellow-800 mb-1">
                      Upgrade to Pro
                    </h4>
                    <p className="text-sm text-yellow-700">
                      Get access to all premium styles and generate up to 500 images monthly!
                    </p>
                    <button className="mt-3 px-4 py-2 bg-gradient-to-r from-yellow-400 to-orange-400 text-white rounded-lg text-sm font-medium hover:from-yellow-500 hover:to-orange-500 transition-all">
                      Contact for Pro Access
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* History Panel */}
            <div className="bg-white rounded-2xl shadow-xl p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold">History</h3>
                <button
                  onClick={() => setHistory([])}
                  className="p-2 text-gray-500 hover:text-gray-700"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
              
              <div className="space-y-4">
                {history.map((item) => (
                  <div
                    key={item.id}
                    className="bg-gray-50 rounded-lg p-3"
                  >
                    <img
                      src={item.imageUrl}
                      alt={item.prompt}
                      className="w-full h-32 object-cover rounded-lg mb-2"
                    />
                    <p className="text-sm text-gray-600 truncate">{item.prompt}</p>
                    <div className="flex justify-end gap-2 mt-2">
                      <button
                        onClick={() => handleDownload(item.imageUrl)}
                        className="p-1.5 text-gray-500 hover:text-gray-700"
                      >
                        <Download className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => {
                          setPrompt(item.prompt);
                          setSelectedStyle(item.style);
                        }}
                        className="p-1.5 text-gray-500 hover:text-gray-700"
                      >
                        <RefreshCw className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextToImage;