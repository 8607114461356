import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageSquare, Cpu,  Users, Brain, ArrowRight, User,  Building2, Store, Hospital, Briefcase, Check, AlertTriangle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useSound } from './hooks/useSound';
import { solutionsDatabase } from './components/data/solutions';


const ToolTip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="inline-flex items-center cursor-help"
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 w-64 p-2 mt-2 text-sm text-white bg-gray-900 rounded-lg shadow-lg -left-28">
          {content}
          <div className="absolute w-3 h-3 -mt-5 rotate-45 bg-gray-900 left-1/2 -translate-x-1/2" />
        </div>
      )}
    </div>
  );
};

const LeadGenerationFlow = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    companyName: '',
    companyCategory: '',  // keep this for industry-specific solutions
    interestedServices: [],
    position: ''
  });

  const [chatState, setChatState] = useState({
    visibleMessages: [],
    isTyping: false,
    language: 'english',
    messageIndex: 0,
    loopCount: 0,
    isPaused: false
  });
  const chatContainerRef = useRef(null);

  const services = [
    { 
      id: 'chatbot', 
      icon: MessageSquare, 
      title: 'AI Chatbot', 
      description: 'Intelligent customer service automation',
      benefits: ['24/7 Customer Support', 'Instant Responses', 'Multilingual Support']
    },
    { 
      id: 'automation', 
      icon: Cpu, 
      title: 'Process Automation', 
      description: 'Streamline your business operations',
      benefits: ['Workflow Optimization', 'Error Reduction', 'Increased Productivity']
    },
    { 
      id: 'analysis', 
      icon: Brain, 
      title: 'Customer Behavior Analysis', 
      description: 'Understand your customers better',
      benefits: ['Pattern Recognition', 'Customer Insights', 'Personalization']
    },
    { 
      id: 'sentiment', 
      icon: Users, 
      title: 'Sentiment Analysis', 
      description: 'Monitor customer satisfaction',
      benefits: ['Brand Monitoring', 'Customer Feedback', 'Reputation Management']
    }
  ];

  const messages = useMemo(() => ({
    english: [
      { type: 'user', name: 'You', text: "Guys, help me somehow please. I can't control and do everything!" },
      { type: 'ai', name: 'AZRI AI', text: "Welcome! How can we help you?" },
      { type: 'user', name: 'You', text: "I need a more effective way to generate more ROI and at the same time work fewer hours because I already have a headache." },
      { type: 'ai', name: 'AZRI AI', text: "You're on the right path. That's exactly what we do! Can you explain to me what you just meant?" },
    ],
    georgian: [
      { type: 'user', name: 'თქვენ', text: "ბიჭებო, როგორმე დამეხმარეთ. ვერ ვაკონტროლებ და ვერ ვაკეთებ ყველაფერს!" },
      { type: 'ai', name: 'AZRI AI', text: "მოგესალმებით! როგო შეგვიძლია დაგეხმაროთ?" },
      { type: 'user', name: 'თქვენ', text: "მჭირდება უფრო ეფექტური გზა ROI-ს გასაზრდელად და ამავდროულად ნაკლები საათების სამუშაოდ, რადგან უკვე თავი მტკივა." },
      { type: 'ai', name: 'AZRI AI', text: "სწორ გზაზე ხართ. ზუსტად ეს არის ის, რასა ჩვენ ვაკეთებთ! შეგიძიათ ამიხსნათ, რას გულისხმობდით?" },
    ]
  }), []);

  const { playMessageSentSound } = useSound();

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required.";
    if (!formData.companyName) errors.companyName = "Company Name is required.";
    if (!formData.companyCategory) errors.companyCategory = "Company Category is required.";
    if (!formData.position) errors.position = "Your Position is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleContinue = () => {
    if (validateForm()) {
      setStep(3);
    }
  };

  const showNextMessage = useCallback(() => {
    setChatState(prevState => {
      const { language, messageIndex, loopCount, isPaused } = prevState;
      const currentMessages = messages[language] || [];

      if (isPaused) {
        return prevState;
      }

      if (messageIndex < currentMessages.length) {
        // Don't play sound here, as we're just starting to type
        return {
          ...prevState,
          isTyping: true,
          messageIndex: messageIndex + 1
        };
      } else {
        // Switch language or start pause
        const newLanguage = language === 'english' ? 'gezorgian' : 'english';
        if (language === 'georgian') {
          return {
            ...prevState,
            isPaused: true,
            isTyping: false
          };
        } else {
          return {
            visibleMessages: [],
            isTyping: true,
            language: newLanguage,
            messageIndex: 0,
            loopCount: loopCount + 1
          };
        }
      }
    });

    const timeoutId = setTimeout(() => {
      setChatState(prevState => {
        const { language, messageIndex, isPaused } = prevState;
        const currentMessages = messages[language] || [];

        if (isPaused) {
          return prevState;
        }

        if (messageIndex > 0 && messageIndex <= currentMessages.length) {
          // Play sound when the message appears
          playMessageSentSound();
          return {
            ...prevState,
            isTyping: false,
            visibleMessages: [
              ...prevState.visibleMessages,
              currentMessages[messageIndex - 1]
            ]
          };
        }
        return prevState;
      });

      const nextTimeoutId = setTimeout(showNextMessage, 1000);
      return () => clearTimeout(nextTimeoutId);
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, [messages, playMessageSentSound]);

  useEffect(() => {
    if (step === 1) {
      const timeoutId = setTimeout(showNextMessage, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [step, showNextMessage]);

  useEffect(() => {
    if (chatState.isPaused) {
      const pauseTimeoutId = setTimeout(() => {
        setChatState(prevState => ({
          ...prevState,
          isPaused: false,
          visibleMessages: [],
          language: 'english',
          messageIndex: 0,
          isTyping: true
        }));
      }, 5000);

      return () => clearTimeout(pauseTimeoutId);
    }
  }, [chatState.isPaused]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatState.visibleMessages, chatState.isTyping]);

  const handleServiceToggle = (serviceId) => {
    setFormData(prev => ({
      ...prev,
      interestedServices: prev.interestedServices.includes(serviceId)
        ? prev.interestedServices.filter(id => id !== serviceId) // Remove service if already selected
        : [...prev.interestedServices, serviceId] // Add service if not selected
    }));
  };

  const positions = ['CEO', 'Manager', 'Developer', 'Designer', 'Sales', 'Marketing'];
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const categories = [
    { 
      id: 'hospitality', 
      label: 'Hospitality', 
      icon: <Building2 className="w-4 h-4" />,
      description: 'Hotels, restaurants, tourism, and entertainment venues'
    },
    { 
      id: 'retail', 
      label: 'Retail', 
      icon: <Store className="w-4 h-4" />,
      description: 'Physical and online stores, merchandise, and retail services'
    },
    { 
      id: 'clinic', 
      label: 'Clinic', 
      icon: <Hospital className="w-4 h-4" />,
      description: 'Medical practices, health services, and wellness centers'
    },
    { 
      id: 'finance', 
      label: 'Finance', 
      icon: <Briefcase className="w-4 h-4" />,
      description: 'Financial services, banking, and investment firms'
    }
  ];

  // First, add a new state to track selected solutions
  const [selectedSolutions, setSelectedSolutions] = useState([]);

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-blue-50 to-indigo-50">
      {/* Logo */}
      <div className="fixed top-6 left-4 md:left-24 z-50">
        <Link to="/" className="text-lg md:text-xl font-semibold bg-gradient-to-r from-blue-600 to-cyan-500 bg-clip-text text-transparent flex items-center">
          AZRI AI
          <span className="text-xs ml-2 text-gray-600 hidden md:inline">AI for Georgian SMEs</span>
        </Link>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={step}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="min-h-screen flex items-center justify-center p-8"
        >
          {/* Step 1 */}
          {step === 1 && (
            <div className="flex w-full max-w-6xl gap-8 h-[600px]">
              <motion.div 
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                className="flex-1 bg-white rounded-2xl p-8 shadow-xl flex flex-col justify-between backdrop-blur-lg bg-opacity-90"
              >
                <div>
                  <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                    Transform Your Business with AI
                  </h1>
                  <p className="text-gray-600 text-xl mb-8 leading-relaxed">
                    Discover how our cutting-edge AI solutions can revolutionize your operations and drive unprecedented growth
                  </p>
                  <div className="flex flex-col space-y-4">
                    {['Boost Efficiency', 'Increase Revenue', 'Enhance Customer Experience'].map((benefit) => (
                      <div key={benefit} className="flex items-center space-x-2 text-gray-700">
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-600" />
                        <span>{benefit}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <button
                  onClick={() => setStep(2)}
                  className="group w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-4 rounded-xl text-lg font-semibold hover:shadow-lg transition-all duration-300 flex items-center justify-center"
                >
                  Start Your Journey
                  <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </button>
              </motion.div>
              <motion.div 
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                className="flex-1 bg-gradient-to-br from-blue-600 to-indigo-600 rounded-2xl p-8 text-white shadow-xl flex flex-col"
              >
                <div ref={chatContainerRef} className="flex-grow overflow-y-auto space-y-4 mb-4">
                  <AnimatePresence>
                    {chatState.visibleMessages.map((message, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className={`flex flex-col ${message.type === 'user' ? 'items-end' : 'items-start'}`}
                      >
                        <div className={`flex items-center space-x-2 ${message.type === 'user' ? 'flex-row-reverse space-x-reverse' : ''}`}>
                          <div className={`p-2 rounded-full ${message.type === 'user' ? 'bg-blue-500' : 'bg-white'}`}>
                            {message.type === 'user' ? <User size={16} /> : <MessageSquare size={16} />}
                          </div>
                          <span className="text-sm text-gray-300">{message.name}</span>
                        </div>
                        <div className={`mt-1 p-3 rounded-lg max-w-[80%] ${
                          message.type === 'user' ? 'bg-blue-500 text-white' : 'bg-white text-blue-900'
                        }`}>
                          {message.text}
                        </div>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
                {chatState.isTyping && (
                  <div className="flex items-center space-x-2">
                    <div className="bg-blue-500 p-2 rounded-full">
                      <MessageSquare size={16} />
                    </div>
                    <div className="bg-gray-200 rounded-full px-4 py-2">
                      <span className="animate-pulse">...</span>
                    </div>
                  </div>
                )}
              </motion.div>
            </div>
          )}

          {/* Step 2: Collect User Information */}
          {step === 2 && (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="w-full max-w-5xl bg-white rounded-2xl p-8 md:p-12 shadow-xl"
            >
              {/* Warning Message */}
              <div className="mb-6 p-4 bg-yellow-50 rounded-lg border border-yellow-200">
                <div className="flex items-start gap-3">
                  <AlertTriangle className="w-5 h-5 text-yellow-600 mt-0.5" />
                  <p className="text-sm text-yellow-800">
                    Please provide accurate information to help our AI better analyze your needs and provide the most relevant solutions.
                  </p>
                </div>
              </div>

              {/* Rest of Step 2 content */}
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Your Name <span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    required
                    value={formData.name}
                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                    className="w-full p-4 rounded-xl border border-gray-300 focus:border-blue-600 focus:ring-2 focus:ring-blue-600 transition-all bg-white shadow-sm hover:shadow-md"
                  />
                  {formErrors.name && <p className="text-red-500 text-sm">{formErrors.name}</p>}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Company Name <span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    required
                    value={formData.companyName}
                    onChange={(e) => setFormData({...formData, companyName: e.target.value})}
                    className="w-full p-4 rounded-xl border border-gray-300 focus:border-blue-600 focus:ring-2 focus:ring-blue-600 transition-all bg-white shadow-sm hover:shadow-md"
                  />
                  {formErrors.companyName && <p className="text-red-500 text-sm">{formErrors.companyName}</p>}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Your Position <span className="text-red-500">*</span></label>
                  <div className="relative">
                    <button
                      onClick={toggleDropdown}
                      className="w-full p-4 rounded-xl border border-gray-300 focus:border-blue-600 focus:ring-2 focus:ring-blue-600 transition-all bg-white shadow-sm hover:shadow-md flex justify-between items-center"
                    >
                      {formData.position || 'Select your position'}
                      <svg className={`w-5 h-5 text-gray-400 transition-transform ${dropdownOpen ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                      </svg>
                    </button>
                    {dropdownOpen && (
                      <ul className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-xl shadow-lg">
                        {positions.map(position => (
                          <li
                            key={position}
                            onClick={() => {
                              setFormData({...formData, position});
                              setDropdownOpen(false);
                            }}
                            className={`p-4 cursor-pointer hover:bg-blue-50 ${formData.position === position ? 'bg-blue-100' : ''}`}
                          >
                            {position}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {formErrors.position && <p className="text-red-500 text-sm">{formErrors.position}</p>}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Company Category <span className="text-red-500">*</span></label>
                  <div className="-mx-2 flex flex-wrap">
                    {categories.map(category => (
                      <ToolTip key={category.id} content={category.description} className="w-full">
                        <button
                          type="button"
                          className={`flex-1 p-4 text-center border rounded-lg transition-all
                            hover:bg-blue-50 flex flex-col items-center justify-center mx-2 mb-2
                            ${formData.companyCategory === category.id 
                              ? 'border-blue-500 bg-blue-50 shadow-sm' 
                              : 'border-gray-200'}`}
                          onClick={() => setFormData({...formData, companyCategory: category.id})}
                        >
                          {category.icon}
                          <span className="ml-2">{category.label}</span>
                        </button>
                      </ToolTip>
                    ))}
                  </div>
                </div>

                <div className="flex justify-between mt-8">
                  <button
                    onClick={() => setStep(1)} // Back button to go to Step 1
                    className="w-full mr-2 bg-gray-300 text-gray-800 py-4 rounded-xl text-lg font-semibold transition-all flex items-center justify-center"
                  >
                    Back
                  </button>
                  <button
                    onClick={handleContinue} // Proceed to Step 3
                    className="w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-4 rounded-xl text-lg font-semibold transition-all flex items-center justify-center"
                  >
                    Continue
                    <ArrowRight className="ml-2 w-5 h-5" />
                  </button>
                </div>
              </div>
            </motion.div>
          )}

          {/* Step 3: Select AI Solutions */}
          {step === 3 && (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="w-full max-w-5xl bg-white rounded-2xl p-8 md:p-12 shadow-xl"
            >
              <h2 className="text-3xl font-bold mb-2 text-gray-900">Select Your AI Solutions</h2>
              <p className="text-gray-600 mb-8">Choose the services that best fit your business needs</p>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {services.map(service => (
                  <motion.div
                    key={service.id}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className={`relative p-6 rounded-xl border-2 cursor-pointer transition-all
                      ${formData.interestedServices.includes(service.id)
                        ? 'border-blue-600 bg-blue-50/50'
                        : 'border-gray-200 hover:border-blue-300 hover:shadow-md'}`}
                    onClick={() => handleServiceToggle(service.id)}
                  >
                    {/* Icon Container */}
                    <div className={`w-12 h-12 rounded-lg flex items-center justify-center mb-4
                      ${formData.interestedServices.includes(service.id)
                        ? 'bg-blue-100'
                        : 'bg-gray-100'}`}>
                      <service.icon 
                        className={`w-6 h-6 transition-colors
                          ${formData.interestedServices.includes(service.id)
                            ? 'text-blue-600'
                            : 'text-gray-600'}`}
                      />
                    </div>

                    {/* Content */}
                    <div className="space-y-3">
                      <h3 className="text-xl font-semibold text-gray-900">{service.title}</h3>
                      <p className="text-gray-600">{service.description}</p>
                      
                      {/* Benefits List */}
                      <motion.div className="space-y-2">
                        {service.benefits.map((benefit, index) => (
                          <motion.div
                            key={index}
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: index * 0.1 }}
                            className="flex items-center text-sm text-gray-500"
                          >
                            <div className={`w-1.5 h-1.5 rounded-full mr-2
                              ${formData.interestedServices.includes(service.id)
                                ? 'bg-blue-600'
                                : 'bg-gray-400'}`}
                            />
                            {benefit}
                          </motion.div>
                        ))}
                      </motion.div>
                    </div>

                    {/* Selected Indicator */}
                    {formData.interestedServices.includes(service.id) && (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        className="absolute top-4 right-4 w-6 h-6 bg-blue-600 rounded-full flex items-center justify-center"
                      >
                        <Check className="w-4 h-4 text-white" />
                      </motion.div>
                    )}
                  </motion.div>
                ))}
              </div>

              {/* Navigation Buttons */}
              <div className="flex justify-between mt-8">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setStep(2)}
                  className="flex-1 mr-2 bg-gray-100 text-gray-800 py-4 rounded-xl text-lg font-semibold 
                    transition-all hover:bg-gray-200 flex items-center justify-center"
                >
                  Back
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setStep(4)}
                  disabled={formData.interestedServices.length === 0}
                  className={`flex-1 py-4 rounded-xl text-lg font-semibold transition-all
                    flex items-center justify-center
                    ${formData.interestedServices.length === 0
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white hover:shadow-lg'}`}
                >
                  Continue
                  <ArrowRight className="ml-2 w-5 h-5" />
                </motion.button>
              </div>
            </motion.div>
          )}

          {/* Step 4: Recommended Solutions */}
          {step === 4 && (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="w-full max-w-5xl bg-white rounded-2xl p-8 md:p-12 shadow-xl"
            >
              {/* Welcome Header */}
              <div className="mb-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-1">
                  Welcome, {formData.name}!
                </h2>
                <p className="text-gray-600 text-sm">
                  Based on your profile as a {formData.position} at {formData.companyName}, let's discuss how our AI solutions can help your {formData.companySize}-person team.
                </p>
              </div>

              {/* Solutions Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Position-specific Solutions */}
                <div className="col-span-2">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4">Position-Specific Solutions</h3>
                </div>
                {solutionsDatabase.positions[formData.position.toLowerCase()]
                  ?.filter(solution => solution.recommended)
                  .slice(0, 3)
                  .map((solution) => (
                    <SolutionCard 
                      key={`position-${solution.id}`} 
                      solution={solution}
                      isSelected={selectedSolutions.includes(solution.id)}
                      onToggle={() => {
                        setSelectedSolutions(prev => 
                          prev.includes(solution.id)
                            ? prev.filter(id => id !== solution.id)
                            : [...prev, solution.id]
                        );
                      }}
                    />
                  ))}

                {/* Category/Industry Solutions */}
                <div className="col-span-2">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4 mt-6">Industry Solutions</h3>
                </div>
                {solutionsDatabase.industries[formData.companyCategory.toLowerCase()]
                  ?.filter(solution => solution.recommended)
                  .slice(0, 3)
                  .map((solution) => (
                    <SolutionCard 
                      key={`industry-${solution.id}`} 
                      solution={solution}
                      isSelected={selectedSolutions.includes(solution.id)}
                      onToggle={() => {
                        setSelectedSolutions(prev => 
                          prev.includes(solution.id)
                            ? prev.filter(id => id !== solution.id)
                            : [...prev, solution.id]
                        );
                      }}
                    />
                  ))}

                {/* Service-specific Solutions */}
                <div className="col-span-2">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4 mt-6">Service Solutions</h3>
                </div>
                {formData.interestedServices.flatMap(service =>
                  (solutionsDatabase.services[service.toLowerCase()] || [])
                    .filter(solution => solution.recommended)
                    .slice(0, 2)
                ).map((solution) => (
                  <SolutionCard 
                    key={`service-${solution.id}`} 
                    solution={solution}
                    isSelected={selectedSolutions.includes(solution.id)}
                    onToggle={() => {
                      setSelectedSolutions(prev => 
                        prev.includes(solution.id)
                          ? prev.filter(id => id !== solution.id)
                          : [...prev, solution.id]
                      );
                    }}
                  />
                ))}

                {/* Combined Position+Category Solutions */}
                <div className="col-span-2">
                  <h3 className="text-lg font-semibold text-gray-900 mb-4 mt-6">Tailored Solutions</h3>
                </div>
                {solutionsDatabase.positions[formData.position.toLowerCase()]
                  ?.filter(solution => 
                    solution.recommended && 
                    solution.industries?.includes(formData.companyCategory.toLowerCase())
                  )
                  .slice(0, 2)
                  .map((solution) => (
                    <SolutionCard 
                      key={`combined-${solution.id}`} 
                      solution={solution}
                      isSelected={selectedSolutions.includes(solution.id)}
                      onToggle={() => {
                        setSelectedSolutions(prev => 
                          prev.includes(solution.id)
                            ? prev.filter(id => id !== solution.id)
                            : [...prev, solution.id]
                        );
                      }}
                    />
                  ))}
              </div>

              {/* Navigation Buttons */}
              <div className="flex justify-between mt-8 col-span-2">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setStep(3)}
                  className="flex-1 mr-2 bg-gray-100 text-gray-800 py-4 rounded-xl text-lg font-semibold transition-all hover:bg-gray-200 flex items-center justify-center"
                >
                  Back
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setStep(5)}
                  disabled={selectedSolutions.length === 0}
                  className={`flex-1 ml-2 py-4 rounded-xl text-lg font-semibold transition-all 
                    flex items-center justify-center
                    ${selectedSolutions.length === 0
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white hover:shadow-lg'}`}
                >
                  Continue ({selectedSolutions.length} selected)
                  <ArrowRight className="ml-2 w-5 h-5" />
                </motion.button>
              </div>
            </motion.div>
          )}

          {/* Step 5: Contact Information */}
          {step === 5 && (
            <div className="w-full max-w-5xl bg-white rounded-2xl p-12 shadow-xl">
              <h2 className="text-3xl font-bold mb-2 text-gray-900">Contact Information</h2>
              <p className="text-gray-600 mb-8">Please provide your contact details for follow-up.</p>
              
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Email</label>
                  <input
                    type="email"
                    required
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                    className="w-full p-4 rounded-xl border border-gray-200 focus:border-blue-600 focus:ring-1 focus:ring-blue-600 transition-colors"
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">Phone Number</label>
                  <input
                    type="tel"
                    required
                    value={formData.phone}
                    onChange={(e) => setFormData({...formData, phone: e.target.value})}
                    className="w-full p-4 rounded-xl border border-gray-200 focus:border-blue-600 focus:ring-1 focus:ring-blue-600 transition-colors"
                  />
                </div>

                <div className="flex justify-between mt-8">
                  <button
                    onClick={() => setStep(4)} // Back button to go to Step 4
                    className="w-full mr-2 bg-gray-300 text-gray-800 py-4 rounded-xl text-lg font-semibold transition-all flex items-center justify-center"
                  >
                    Back
                  </button>
                  <button
                    onClick={() => setStep(6)} // Proceed to Step 6 (Schedule Meeting)
                    className="w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-4 rounded-xl text-lg font-semibold transition-all flex items-center justify-center"
                  >
                    Continue
                    <ArrowRight className="ml-2 w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Step 6: Schedule Meeting */}
          {step === 6 && (
            <div className="w-full max-w-5xl bg-white rounded-2xl p-12 shadow-xl">
              <h2 className="text-3xl font-bold mb-2 text-gray-900">Schedule a Meeting</h2>
              <p className="text-gray-600 mb-8">Select a date and time that works for you.</p>
              <div className="space-y-4">
                <label className="text-sm font-medium text-gray-700">Select Date:</label>
                <input type="date" className="w-full p-4 rounded-xl border border-gray-200 focus:border-blue-600 focus:ring-1 focus:ring-blue-600 transition-colors" />
                
                <label className="text-sm font-medium text-gray-700">Select Time:</label>
                <input type="time" className="w-full p-4 rounded-xl border border-gray-200 focus:border-blue-600 focus:ring-1 focus:ring-blue-600 transition-colors" />
              </div>
              <div className="flex justify-between mt-8">
                <button
                  onClick={() => setStep(5)} // Back button to go to Step 5
                  className="w-full mr-2 bg-gray-300 text-gray-800 py-4 rounded-xl text-lg font-semibold transition-all flex items-center justify-center"
                >
                  Back
                </button>
                <button
                  onClick={() => setStep(7)} // Proceed to Step 7 (Thank You)
                  className="w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-4 rounded-xl text-lg font-semibold transition-all flex items-center justify-center"
                >
                  Confirm
                  <ArrowRight className="ml-2 w-5 h-5" />
                </button>
              </div>
            </div>
          )}

          {/* Step 7: Thank You */}
          {step === 7 && (
            <div className="flex w-full max-w-6xl gap-8 h-[600px]">
              <motion.div 
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                className="flex-1 bg-white rounded-2xl p-8 shadow-xl flex flex-col justify-between"
              >
                <div>
                  <h1 className="text-5xl font-bold mb-6 text-center text-gray-900">
                    Thank You, {formData.name}!
                  </h1>
                  <p className="text-gray-600 text-xl mb-8 text-center leading-relaxed">
                    Your request has been received. We will contact you shortly at {formData.email} or {formData.phone} for confirmation.
                  </p>
                </div>
                <button
                  onClick={() => setStep(1)} // Restart flow
                  className="w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-4 rounded-xl text-lg font-semibold transition-all flex items-center justify-center"
                >
                  Start Again
                </button>
              </motion.div>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

// Separate component for the solution card to keep code DRY
const SolutionCard = ({ solution, isSelected, onToggle }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    onClick={onToggle}
    className={`p-6 rounded-xl border transition-all duration-200 cursor-pointer
      ${isSelected 
        ? 'border-blue-500 bg-blue-50/50 shadow-md' 
        : 'hover:border-blue-500'}`}
  >
    <div className="flex items-start justify-between mb-4">
      <div>
        <h3 className="text-xl font-semibold text-gray-900">{solution.title}</h3>
        <p className="text-gray-600 mt-1">{solution.description}</p>
      </div>
      <div className={`w-12 h-12 rounded-lg flex items-center justify-center
        ${isSelected ? 'bg-blue-100' : 'bg-blue-50'}`}>
        {isSelected ? (
          <Check className="w-6 h-6 text-blue-500" />
        ) : (
          <solution.icon className="w-6 h-6 text-blue-500" />
        )}
      </div>
    </div>

    <div className="space-y-4">
      {/* Features */}
      <div>
        <h4 className="text-sm font-semibold text-gray-700 mb-2">Features:</h4>
        <ul className="grid grid-cols-2 gap-2">
          {solution.features.map((feature, index) => (
            <li key={index} className="flex items-center text-sm text-gray-600">
              <Check className="w-4 h-4 text-green-500 mr-2" />
              {feature}
            </li>
          ))}
        </ul>
      </div>

      {/* Benefits */}
      <div>
        <h4 className="text-sm font-semibold text-gray-700 mb-2">Benefits:</h4>
        <ul className="grid grid-cols-2 gap-2">
          {solution.benefits.map((benefit, index) => (
            <li key={index} className="flex items-center text-sm text-gray-600">
              <Check className="w-4 h-4 text-green-500 mr-2" />
              {benefit}
            </li>
          ))}
        </ul>
      </div>

      {/* Implementation Details */}
      <div className="flex items-center justify-between text-sm text-gray-600 pt-4 border-t">
        <span>Complexity: {solution.complexity}</span>
        <span>Time: {solution.implementationTime}</span>
        <span>Cost: {solution.priceRange}</span>
      </div>
    </div>
  </motion.div>
);

export default LeadGenerationFlow;
