import React from 'react';
import { motion } from 'framer-motion';
import { 
  Facebook, 
  Twitter, 
  Instagram, 
  Linkedin,
  Mail,
  Phone,
  MapPin
} from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const socialLinks = [
    { Icon: Facebook, href: "https://facebook.com/azriai" },
    { Icon: Twitter, href: "https://twitter.com/azriai" },
    { Icon: Instagram, href: "https://instagram.com/azriai" },
    { Icon: Linkedin, href: "https://linkedin.com/company/azriai" }
  ];

  const quickLinks = [
    { name: 'Home', href: '/' },
    { name: 'Services', href: '/services' },
    { name: 'Products', href: '/products' },
    { name: 'About Us', href: '/about' },
    { name: 'Contact', href: '/contact' }
  ];

  return (
    <footer className="bg-gray-50 dark:bg-gray-900 text-gray-600 dark:text-gray-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 lg:py-12">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
          {/* Company Info */}
          <div className="col-span-2 md:col-span-1 space-y-3 md:space-y-4">
            <h3 className="text-lg md:text-xl font-bold text-gray-900 dark:text-white">AZRI AI</h3>
            <p className="text-xs md:text-sm">
              Pioneering AI solutions for Georgian businesses, making technology accessible and effective.
            </p>
            <div className="flex space-x-4">
              {socialLinks.map(({ Icon, href }, index) => (
                <motion.a
                  key={index}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.1 }}
                  className="text-gray-400 hover:text-gray-600 dark:hover:text-white transition-colors"
                >
                  <Icon size={18} />
                </motion.a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-sm md:text-lg font-semibold text-gray-900 dark:text-white mb-3 md:mb-4">Quick Links</h4>
            <ul className="space-y-2 text-xs md:text-sm">
              {quickLinks.map(({ name, href }) => (
                <li key={name}>
                  <a 
                    href={href} 
                    className="hover:text-gray-900 dark:hover:text-white transition-colors"
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Services */}
          <div>
            <h4 className="text-sm md:text-lg font-semibold text-gray-900 dark:text-white mb-3 md:mb-4">Services</h4>
            <ul className="space-y-2 text-xs md:text-sm">
              {[
                'AI Chatbots',
                'Virtual Try-On',
                'Text-to-Image AI',
                'Custom AI Solutions',
                'Business Automation'
              ].map((service) => (
                <li key={service}>
                  <a 
                    href="/services" 
                    className="hover:text-gray-900 dark:hover:text-white transition-colors"
                  >
                    {service}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h4 className="text-sm md:text-lg font-semibold text-gray-900 dark:text-white mb-3 md:mb-4">Contact Us</h4>
            <ul className="space-y-3 md:space-y-4 text-xs md:text-sm">
              <li className="flex items-center space-x-3">
                <Mail size={16} className="text-blue-500 flex-shrink-0" />
                <a 
                  href="mailto:Info@Azriai.com"
                  className="hover:text-gray-900 dark:hover:text-white transition-colors"
                >
                  Info@Azriai.com
                </a>
              </li>
              <li className="flex items-center space-x-3">
                <Phone size={16} className="text-blue-500 flex-shrink-0" />
                <a 
                  href="tel:+995XXXXXXXX"
                  className="hover:text-gray-900 dark:hover:text-white transition-colors"
                >
                  +995 XXX XXX XXX
                </a>
              </li>
              <li className="flex items-center space-x-3">
                <MapPin size={16} className="text-blue-500 flex-shrink-0" />
                <a 
                  href="https://maps.google.com/?q=Tbilisi,Georgia"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-900 dark:hover:text-white transition-colors"
                >
                  Tbilisi, Georgia
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-200 dark:border-gray-800 mt-8 md:mt-12 pt-6 md:pt-8 text-xs md:text-sm text-center">
          <p className="text-gray-500 dark:text-gray-400">© {currentYear} AZRI AI. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 