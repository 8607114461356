import { 
    MessageSquare, FileText, Database, Calendar, Mail, 
    Settings, Users,  ShoppingBag, 
    FileSpreadsheet,  BarChart2,  ClipboardList, Bell, TrendingUp,
  } from 'lucide-react';
   
  export const solutionsDatabase = {
    positions: {
      marketing: [
        {
          id: 'form_to_sheets',
          title: "Form to Spreadsheet Automation",
          description: "Automatically collect form responses in spreadsheets",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Google Forms integration",
            "Auto-updating sheets",
            "Email notifications",
            "Data organization"
          ],
          benefits: [
            "No manual data entry",
            "Always updated sheets",
            "Organized responses",
            "Save hours weekly"
          ],
          icon: FileSpreadsheet,
          recommended: true
        },
        {
          id: 'social_monitoring',
          title: "Social Media Monitor",
          description: "Track mentions and engage automatically",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Mention tracking",
            "Auto-responses",
            "Sentiment detection",
            "Engagement alerts"
          ],
          benefits: [
            "Never miss mentions",
            "Quick responses",
            "Better engagement",
            "Brand protection"
          ],
          icon: MessageSquare,
          recommended: false
        },
        {
          id: 'website_lead_capture',
          title: "Website to CRM Bridge",
          description: "Capture website leads directly to your CRM",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Form integration",
            "CRM auto-update",
            "Lead notification",
            "Data enrichment"
          ],
          benefits: [
            "No lost leads",
            "Faster follow-up",
            "Better organization",
            "Enhanced data"
          ],
          icon: Database,
          recommended: true
        },
        {
          id: 'content_scheduler',
          title: "Multi-Platform Publisher",
          description: "Schedule content across all platforms",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Multi-platform posting",
            "Content calendar",
            "Auto-scheduling",
            "Performance tracking"
          ],
          benefits: [
            "Save posting time",
            "Consistent presence",
            "Better planning",
            "Cross-platform reach"
          ],
          icon: Calendar,
          recommended: false
        },
        {
          id: 'competitor_monitor',
          title: "Competitor Price Tracker",
          description: "Monitor competitor prices and changes",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Price monitoring",
            "Change alerts",
            "Data collection",
            "Trend analysis"
          ],
          benefits: [
            "Stay competitive",
            "Quick reactions",
            "Market insights",
            "Better pricing"
          ],
          icon: BarChart2,
          recommended: false
        },
        {
          id: 'review_collector',
          title: "Review Aggregator",
          description: "Collect and monitor reviews automatically",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Review collection",
            "Sentiment analysis",
            "Response alerts",
            "Performance tracking"
          ],
          benefits: [
            "Better reputation",
            "Quick responses",
            "Customer insights",
            "Save monitoring time"
          ],
          icon: MessageSquare,
          recommended: true
        }
      ],
      sales: [
        {
          id: 'lead_qualifier',
          title: "Lead Qualification Bot",
          description: "Automatically qualify and score leads",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Lead scoring system",
            "Qualification questions",
            "CRM integration",
            "Email notifications"
          ],
          benefits: [
            "Focus on hot leads",
            "Save qualification time",
            "Never miss opportunities",
            "Better conversion rates"
          ],
          icon: Users,
          recommended: true
        },
        {
          id: 'meeting_scheduler',
          title: "Smart Meeting Scheduler",
          description: "Automate meeting scheduling process",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Calendar integration",
            "Automated reminders",
            "Meeting link generation",
            "Follow-up emails"
          ],
          benefits: [
            "No scheduling hassle",
            "Fewer no-shows",
            "Save coordination time",
            "Professional image"
          ],
          icon: Calendar,
          recommended: true
        },
        {
          id: 'proposal_generator',
          title: "Proposal Automation",
          description: "Create and track sales proposals",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Template system",
            "Auto-fill client data",
            "Digital signatures",
            "Tracking & reminders"
          ],
          benefits: [
            "Faster proposals",
            "Professional documents",
            "Better follow-up",
            "Higher close rates"
          ],
          icon: FileText,
          recommended: true
        },
        {
          id: 'deal_tracker',
          title: "Pipeline Manager",
          description: "Track and manage sales pipeline",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Deal tracking",
            "Stage automation",
            "Task reminders",
            "Performance metrics"
          ],
          benefits: [
            "Never lose track",
            "Better forecasting",
            "Increased efficiency",
            "Higher close rates"
          ],
          icon: BarChart2,
          recommended: false
        },
        {
          id: 'quote_generator',
          title: "Quote Generator",
          description: "Create and send quotes automatically",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Template system",
            "Price calculation",
            "PDF generation",
            "Email integration"
          ],
          benefits: [
            "Faster quotes",
            "No calculation errors",
            "Professional look",
            "Better tracking"
          ],
          icon: FileSpreadsheet,
          recommended: false
        },
        {
          id: 'follow_up_manager',
          title: "Follow-up Automator",
          description: "Never miss following up with prospects",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Automated sequences",
            "Email templates",
            "Response tracking",
            "Task creation"
          ],
          benefits: [
            "Consistent follow-up",
            "Save time",
            "Never forget",
            "More closes"
          ],
          icon: Mail,
          recommended: true
        },
        {
          id: 'contract_manager',
          title: "Contract Automation",
          description: "Streamline contract process",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Template system",
            "Auto-fill data",
            "E-signatures",
            "Document tracking"
          ],
          benefits: [
            "Faster closing",
            "No manual work",
            "Professional process",
            "Better organization"
          ],
          icon: FileText,
          recommended: false
        },
        {
          id: 'sales_dashboard',
          title: "Sales Analytics",
          description: "Track all sales metrics automatically",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Real-time metrics",
            "Goal tracking",
            "Performance reports",
            "Forecast automation"
          ],
          benefits: [
            "Better insights",
            "Data-driven decisions",
            "Time savings",
            "Improved performance"
          ],
          icon: BarChart2,
          recommended: true
        }
      ],
      developer: [
        {
          id: 'api_monitor',
          title: "API Health Monitor",
          description: "Monitor API performance and uptime",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Uptime monitoring",
            "Response time tracking",
            "Error detection",
            "Alert system"
          ],
          benefits: [
            "Instant notifications",
            "Better reliability",
            "Quick problem detection",
            "Performance insights"
          ],
          icon: Settings,
          recommended: true
        },
        {
          id: 'github_automator',
          title: "GitHub Workflow Automator",
          description: "Automate GitHub processes and notifications",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "PR notifications",
            "Issue management",
            "Branch sync",
            "Deploy alerts"
          ],
          benefits: [
            "Better collaboration",
            "Time savings",
            "Nothing missed",
            "Streamlined workflow"
          ],
          icon: Database,
          recommended: true
        },
        {
          id: 'code_backup',
          title: "Code Backup System",
          description: "Automated code and database backups",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Scheduled backups",
            "Multi-location storage",
            "Version tracking",
            "Restore automation"
          ],
          benefits: [
            "Data safety",
            "Easy recovery",
            "Peace of mind",
            "Version control"
          ],
          icon: Database,
          recommended: false
        },
        {
          id: 'deploy_manager',
          title: "Deployment Manager",
          description: "Automate deployment processes",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Deployment automation",
            "Environment sync",
            "Rollback system",
            "Status alerts"
          ],
          benefits: [
            "Faster deployments",
            "Fewer errors",
            "Better control",
            "Team coordination"
          ],
          icon: Settings,
          recommended: true
        },
        {
          id: 'bug_tracker',
          title: "Bug Tracking Automator",
          description: "Streamline bug tracking and fixes",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Issue tracking",
            "Priority sorting",
            "Team assignment",
            "Progress monitoring"
          ],
          benefits: [
            "Better organization",
            "Faster fixes",
            "Clear priorities",
            "Team efficiency"
          ],
          icon: ClipboardList,
          recommended: true
        },
        {
          id: 'test_automator',
          title: "Test Automation Manager",
          description: "Manage and run automated tests",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Test scheduling",
            "Result reporting",
            "Error logging",
            "Performance metrics"
          ],
          benefits: [
            "Consistent testing",
            "Time savings",
            "Better quality",
            "Quick feedback"
          ],
          icon: Settings,
          recommended: false
        },
        {
          id: 'dev_dashboard',
          title: "Developer Analytics",
          description: "Track development metrics and performance",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Code metrics",
            "Team productivity",
            "Project progress",
            "Quality scores"
          ],
          benefits: [
            "Better insights",
            "Team performance",
            "Project visibility",
            "Quality control"
          ],
          icon: BarChart2,
          recommended: true
        },
        {
          id: 'doc_generator',
          title: "Documentation Generator",
          description: "Automate technical documentation",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Auto-documentation",
            "API docs",
            "Version control",
            "Search optimization"
          ],
          benefits: [
            "Save time",
            "Better docs",
            "Team knowledge",
            "Easy updates"
          ],
          icon: FileText,
          recommended: false
        }
      ],
      manager: [
        {
          id: 'team_dashboard',
          title: "Team Performance Dashboard",
          description: "Track team metrics and performance",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Real-time metrics",
            "Team productivity",
            "Goal tracking",
            "Performance alerts"
          ],
          benefits: [
            "Better oversight",
            "Data-driven decisions",
            "Team motivation",
            "Clear progress"
          ],
          icon: BarChart2,
          recommended: true
        },
        {
          id: 'task_automator',
          title: "Task Management System",
          description: "Automate task assignment and tracking",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Auto-assignment",
            "Progress tracking",
            "Deadline alerts",
            "Workload balancing"
          ],
          benefits: [
            "Better organization",
            "Time savings",
            "Nothing missed",
            "Team efficiency"
          ],
          icon: ClipboardList,
          recommended: true
        },
        {
          id: 'report_generator',
          title: "Automated Reporting",
          description: "Generate and distribute reports automatically",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Data compilation",
            "Report scheduling",
            "Multi-format export",
            "Auto-distribution"
          ],
          benefits: [
            "Save time",
            "Better insights",
            "Regular updates",
            "Professional reports"
          ],
          icon: FileText,
          recommended: true
        },
        {
          id: 'meeting_organizer',
          title: "Meeting Management System",
          description: "Streamline meeting organization",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Calendar sync",
            "Agenda creation",
            "Minutes recording",
            "Action tracking"
          ],
          benefits: [
            "Better meetings",
            "Time efficiency",
            "Clear follow-up",
            "Team alignment"
          ],
          icon: Calendar,
          recommended: false
        },
        {
          id: 'budget_tracker',
          title: "Budget Management System",
          description: "Track and manage department budgets",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Expense tracking",
            "Budget alerts",
            "Report generation",
            "Forecast tools"
          ],
          benefits: [
            "Better control",
            "Cost savings",
            "Clear overview",
            "Smart planning"
          ],
          icon: FileSpreadsheet,
          recommended: true
        },
        {
          id: 'resource_planner',
          title: "Resource Planning Assistant",
          description: "Optimize team resource allocation",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Capacity planning",
            "Skill matching",
            "Availability tracking",
            "Workload optimization"
          ],
          benefits: [
            "Better utilization",
            "Fair distribution",
            "Project success",
            "Team satisfaction"
          ],
          icon: Users,
          recommended: false
        },
        {
          id: 'approval_flow',
          title: "Approval Workflow Automator",
          description: "Streamline approval processes",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Request routing",
            "Status tracking",
            "Reminder system",
            "Audit trail"
          ],
          benefits: [
            "Faster approvals",
            "Better tracking",
            "Compliance",
            "Time savings"
          ],
          icon: Settings,
          recommended: true
        },
        {
          id: 'team_communicator',
          title: "Team Communication Hub",
          description: "Automate team communications",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Update automation",
            "Channel management",
            "Response tracking",
            "Feedback collection"
          ],
          benefits: [
            "Better communication",
            "Team alignment",
            "Time efficiency",
            "Clear messages"
          ],
          icon: MessageSquare,
          recommended: false
        }
      ]
    },

    industries: {
      retail: [
        {
          id: 'inventory_sync',
          title: "Inventory Sync System",
          description: "Sync inventory across all platforms",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Multi-platform sync",
            "Stock alerts",
            "Auto-updates",
            "Sales tracking"
          ],
          benefits: [
            "No overselling",
            "Stock accuracy",
            "Time savings",
            "Better management"
          ],
          icon: ShoppingBag,
          recommended: true
        },
        {
          id: 'price_updater',
          title: "Price Update Automator",
          description: "Manage prices across platforms",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Bulk updates",
            "Competition tracking",
            "Price rules",
            "Change history"
          ],
          benefits: [
            "Stay competitive",
            "Save time",
            "Consistent pricing",
            "Better margins"
          ],
          icon: FileSpreadsheet,
          recommended: true
        },
        {
          id: 'order_processor',
          title: "Order Management System",
          description: "Automate order processing workflow",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Order tracking",
            "Status updates",
            "Invoice generation",
            "Shipping labels"
          ],
          benefits: [
            "Faster processing",
            "Fewer errors",
            "Happy customers",
            "Time savings"
          ],
          icon: ClipboardList,
          recommended: true
        }
      ],

      restaurant: [
        {
          id: 'menu_sync',
          title: "Menu Sync System",
          description: "Sync menus across platforms",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Multi-platform updates",
            "Price management",
            "Availability tracking",
            "Special offers"
          ],
          benefits: [
            "Consistent menus",
            "Time savings",
            "Error reduction",
            "Better service"
          ],
          icon: FileText,
          recommended: true
        },
        {
          id: 'order_collector',
          title: "Order Aggregator",
          description: "Collect orders from all platforms",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Multi-platform integration",
            "Order notifications",
            "Status tracking",
            "Performance analytics"
          ],
          benefits: [
            "No missed orders",
            "Faster processing",
            "Better overview",
            "Happy customers"
          ],
          icon: ShoppingBag,
          recommended: true
        },
        {
          id: 'reservation_manager',
          title: "Reservation System",
          description: "Manage bookings automatically",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Online bookings",
            "Table management",
            "SMS confirmations",
            "Capacity tracking"
          ],
          benefits: [
            "No double bookings",
            "Better planning",
            "Full tables",
            "Happy guests"
          ],
          icon: Calendar,
          recommended: true
        }
      ],

      healthcare: [
        {
          id: 'appointment_scheduler',
          title: "Appointment Manager",
          description: "Automate appointment scheduling",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Online booking",
            "Reminders",
            "Calendar sync",
            "Patient notifications"
          ],
          benefits: [
            "Fewer no-shows",
            "Better scheduling",
            "Time savings",
            "Happy patients"
          ],
          icon: Calendar,
          recommended: true
        },
        {
          id: 'patient_communicator',
          title: "Patient Communication System",
          description: "Automate patient communications",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Appointment reminders",
            "Follow-up messages",
            "Feedback collection",
            "Health tips"
          ],
          benefits: [
            "Better engagement",
            "Fewer missed appointments",
            "Patient satisfaction",
            "Time savings"
          ],
          icon: MessageSquare,
          recommended: true
        },
        {
          id: 'record_organizer',
          title: "Medical Records Organizer",
          description: "Organize patient records digitally",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Digital storage",
            "Quick retrieval",
            "Access control",
            "Backup system"
          ],
          benefits: [
            "Better organization",
            "Quick access",
            "Data security",
            "Space savings"
          ],
          icon: FileText,
          recommended: true
        }
      ],

      finance: [
        {
          id: 'transaction_monitor',
          title: "Transaction Monitor",
          description: "Monitor and track financial transactions",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Real-time monitoring",
            "Anomaly detection",
            "Alert system",
            "Report generation"
          ],
          benefits: [
            "Better security",
            "Fraud prevention",
            "Quick response",
            "Clear oversight"
          ],
          icon: BarChart2,
          recommended: true
        },
        {
          id: 'invoice_automator',
          title: "Invoice Management System",
          description: "Automate invoice processing and tracking",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Auto-generation",
            "Payment tracking",
            "Reminder system",
            "Excel integration"
          ],
          benefits: [
            "Faster payments",
            "Better cash flow",
            "Time savings",
            "Organized records"
          ],
          icon: FileText,
          recommended: true
        },
        {
          id: 'expense_tracker',
          title: "Expense Management",
          description: "Track and manage business expenses",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Receipt processing",
            "Category tracking",
            "Approval workflow",
            "Report generation"
          ],
          benefits: [
            "Better control",
            "Easy reporting",
            "Policy compliance",
            "Cost savings"
          ],
          icon: FileSpreadsheet,
          recommended: true
        },
        {
          id: 'payment_reconciliation',
          title: "Payment Reconciliation",
          description: "Automate payment matching and reconciliation",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Auto-matching",
            "Discrepancy alerts",
            "Bank integration",
            "Audit trail"
          ],
          benefits: [
            "Fewer errors",
            "Time savings",
            "Better accuracy",
            "Clear records"
          ],
          icon: Database,
          recommended: false
        },
        {
          id: 'financial_reporter',
          title: "Financial Report Generator",
          description: "Automate financial report creation",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Multi-format reports",
            "Data compilation",
            "Scheduled generation",
            "Distribution system"
          ],
          benefits: [
            "Time efficiency",
            "Better accuracy",
            "Professional reports",
            "Easy sharing"
          ],
          icon: FileSpreadsheet,
          recommended: true
        }
      ]
    },

    services: {
      chatbot: [
        {
          id: 'customer_service_bot',
          title: "Customer Service Bot",
          description: "24/7 automated customer support",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "FAQ handling",
            "Multi-language",
            "Smart responses",
            "Human handoff"
          ],
          benefits: [
            "24/7 availability",
            "Instant responses",
            "Reduced workload",
            "Better service"
          ],
          icon: MessageSquare,
          recommended: true
        },
        {
          id: 'lead_capture_bot',
          title: "Lead Generation Bot",
          description: "Capture and qualify leads automatically",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Lead qualification",
            "Data collection",
            "CRM integration",
            "Follow-up automation"
          ],
          benefits: [
            "More leads",
            "Better qualification",
            "24/7 capture",
            "Time savings"
          ],
          icon: Users,
          recommended: true
        },
        {
          id: 'booking_bot',
          title: "Appointment Booking Bot",
          description: "Automate appointment scheduling",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Calendar integration",
            "Availability check",
            "Confirmation system",
            "Reminder sending"
          ],
          benefits: [
            "24/7 booking",
            "No scheduling conflicts",
            "Better convenience",
            "Time savings"
          ],
          icon: Calendar,
          recommended: false
        }
      ],

      automation: [
        {
          id: 'data_sync',
          title: "Data Sync Automator",
          description: "Keep your data in sync across platforms",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Multi-platform sync",
            "Real-time updates",
            "Error handling",
            "Audit logging"
          ],
          benefits: [
            "Data accuracy",
            "Time savings",
            "No manual entry",
            "Error reduction"
          ],
          icon: Database,
          recommended: true
        },
        {
          id: 'document_processor',
          title: "Document Automation",
          description: "Automate document creation and processing",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Template system",
            "Data merge",
            "Format conversion",
            "Auto-filing"
          ],
          benefits: [
            "Faster processing",
            "Error reduction",
            "Consistency",
            "Time savings"
          ],
          icon: FileText,
          recommended: true
        },
        {
          id: 'notification_system',
          title: "Smart Notification System",
          description: "Automate alerts and notifications",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Multi-channel alerts",
            "Condition-based",
            "Scheduling",
            "Template system"
          ],
          benefits: [
            "Never miss updates",
            "Better communication",
            "Time savings",
            "Team alignment"
          ],
          icon: Bell,
          recommended: false
        }
      ],

      analytics: [
        {
          id: 'performance_analytics',
          title: "Performance Analytics",
          description: "Track and analyze business metrics",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "KPI tracking",
            "Custom dashboards",
            "Automated reports",
            "Trend analysis"
          ],
          benefits: [
            "Better insights",
            "Data-driven decisions",
            "Clear overview",
            "Time savings"
          ],
          icon: BarChart2,
          recommended: true
        },
        {
          id: 'customer_analytics',
          title: "Customer Behavior Analytics",
          description: "Analyze customer interactions and behavior",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Behavior tracking",
            "Pattern detection",
            "Segment analysis",
            "Report generation"
          ],
          benefits: [
            "Better understanding",
            "Targeted marketing",
            "Improved service",
            "Higher retention"
          ],
          icon: Users,
          recommended: true
        },
        {
          id: 'sales_analytics',
          title: "Sales Performance Analytics",
          description: "Track and analyze sales metrics",
          complexity: "Easy",
          implementationTime: "1 week",
          priceRange: "₾",
          features: [
            "Sales tracking",
            "Pipeline analysis",
            "Forecast modeling",
            "Team performance"
          ],
          benefits: [
            "Better forecasting",
            "Performance insights",
            "Team motivation",
            "Higher sales"
          ],
          icon: TrendingUp,
          recommended: false
        }
      ]
    }
  };
 
  export default solutionsDatabase;