import React, { useState, useEffect, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { 
  ChevronRight, 
  MessageSquare, 


  Users, 
  Play,
  Cpu,
  Code,

  Check,
  Building2,
  Lightbulb,
  TrendingUp,
  Shield,
  ImageIcon, 
  Shirt, 
  FileSearch, 
  ImagePlus, 
  Wand2,
} from 'lucide-react';
import { TypeAnimation } from 'react-type-animation';
import Footer from './components/Footer';

const aiTools = [
  {
    icon: ImageIcon,
    title: "Text-to-Image AI",
    status: null,
    description: "Transform text descriptions into unique, high-quality images instantly.",
    features: ["Custom Style Generation", "Bulk Creation", "Commercial Rights"],
    isActive: true
  },
  {
    icon: Shirt,
    title: "Virtual Try-On",
    status: "beta",
    description: "Experience virtual fitting with advanced AR technology.",
    features: ["Real-time Preview", "Multiple Styles", "Easy Integration"],
    isActive: false
  },
  {
    icon: MessageSquare,
    title: "Conversational AI",
    status: "coming soon",
    description: "Advanced Georgian language chatbot for seamless communication.",
    features: ["Georgian Support", "Smart Responses", "24/7 Available"],
    isActive: false
  },
  {
    icon: FileSearch,
    title: "File Analyzer",
    status: "coming soon",
    description: "Intelligent document analysis and data extraction system.",
    features: ["Smart Scanning", "Data Extraction", "Multi-format"],
    isActive: false
  },
  {
    icon: ImagePlus,
    title: "Image Enhancer",
    status: "coming soon",
    description: "Professional-grade image enhancement and optimization.",
    features: ["Auto Enhance", "Batch Process", "Quality Boost"],
    isActive: false
  },
  {
    icon: Wand2,
    title: "Image Filler",
    status: "coming soon",
    description: "AI-powered content-aware fill and image expansion.",
    features: ["Smart Fill", "Seamless Edit", "Natural Look"],
    isActive: false
  }
];

// Add this section header component
const SectionHeader = () => (
  <div className="text-center mb-6">
    <div className="inline-flex items-center justify-center p-2 bg-blue-500/10 rounded-xl">
      <Cpu className="w-4 h-4 text-blue-400 mr-2" />
      <span className="text-sm font-medium text-blue-400">
        AI Tools
      </span>
    </div>
  </div>
);

const AIToolCard = ({ tool, index }) => (
  <motion.div
    className="relative w-[260px] sm:w-[300px] h-[120px] sm:h-[140px] flex-shrink-0 snap-center group"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: index * 0.1 }}
    whileHover={{ scale: 1.02 }}
  >
    <div className="h-full bg-white dark:bg-gray-800/80 backdrop-blur-sm rounded-xl sm:rounded-2xl p-3 sm:p-4 
                    hover:bg-gray-50 dark:hover:bg-[#1e2445]/90 transition-all duration-300 
                    border border-gray-200 dark:border-transparent 
                    shadow-lg hover:shadow-xl">
      {/* Glow effect */}
      <div className="absolute inset-0 bg-blue-500/0 group-hover:bg-blue-500/5 rounded-xl sm:rounded-2xl transition-all duration-300" />
      
      <div className="flex flex-col h-full relative">
        {/* Top Row - Icon and Title */}
        <div className="flex items-start mb-1.5 sm:mb-2">
          <div className="w-6 h-6 sm:w-8 sm:h-8 rounded-lg bg-blue-50 dark:bg-[#1e2754] flex items-center justify-center group-hover:bg-blue-100 dark:group-hover:bg-blue-900/50 transition-colors duration-300 mr-2 sm:mr-3">
            <tool.icon className="w-3 h-3 sm:w-4 sm:h-4 text-blue-600 dark:text-blue-400 group-hover:text-blue-700 dark:group-hover:text-blue-300" />
          </div>
          <div className="flex-grow flex items-start justify-between">
            <h3 className="text-xs sm:text-sm font-semibold text-gray-900 dark:text-white group-hover:text-blue-600 dark:group-hover:text-blue-100">
              {tool.title}
            </h3>
            {tool.status && (
              <motion.span 
                className={`px-1.5 sm:px-2 py-0.5 text-[8px] sm:text-[10px] font-medium rounded-full ${
                  tool.status === 'beta' 
                    ? 'bg-blue-100 dark:bg-blue-500/20 text-blue-600 dark:text-blue-400'
                    : 'bg-gray-100 dark:bg-gray-700/50 text-gray-600 dark:text-gray-400'
                }`}
              >
                {tool.status}
              </motion.span>
            )}
          </div>
        </div>

        {/* Description */}
        <p className="text-[10px] sm:text-xs text-gray-600 dark:text-gray-400 line-clamp-1 mb-1.5 sm:mb-2 group-hover:text-gray-700 dark:group-hover:text-gray-300">
          {tool.description}
        </p>

        {/* Features */}
        <div className="flex flex-wrap gap-1.5 sm:gap-2 mb-2 sm:mb-3">
          {tool.features.slice(0, 2).map((feature, idx) => (
            <span 
              key={idx}
              className="text-[8px] sm:text-[10px] text-gray-600 dark:text-gray-400 bg-gray-100 dark:bg-gray-800/40 px-1.5 sm:px-2 py-0.5 rounded-full flex items-center"
            >
              <Check className="w-2 h-2 sm:w-2.5 sm:h-2.5 text-blue-600 dark:text-blue-400 mr-0.5 sm:mr-1" />
              {feature}
            </span>
          ))}
        </div>

        {/* Try Now Button */}
        <motion.button
          className={`w-full px-2 sm:px-3 py-1 sm:py-1.5 rounded-full text-[10px] sm:text-xs font-medium transition-all duration-300 flex items-center justify-center mt-auto ${
            tool.isActive
              ? 'bg-blue-100 dark:bg-blue-500/20 hover:bg-blue-200 dark:hover:bg-blue-500/30 text-blue-600 dark:text-blue-400'
              : 'bg-gray-100 dark:bg-gray-800/50 text-gray-600 dark:text-gray-400 cursor-not-allowed'
          }`}
          disabled={!tool.isActive}
          whileHover={tool.isActive ? { scale: 1.02 } : {}}
          whileTap={tool.isActive ? { scale: 0.98 } : {}}
        >
          Try Now
          <ChevronRight className="w-2.5 h-2.5 sm:w-3 sm:h-3 ml-1 group-hover:translate-x-0.5 transition-transform duration-300" />
        </motion.button>
      </div>
    </div>
  </motion.div>
);

const AIToolsSection = () => {
  const scrollContainerRef = useRef(null);
  const [autoScroll,] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let scrollInterval;
    
    if (autoScroll && !isHovered && scrollContainerRef.current) {
      scrollInterval = setInterval(() => {
        const container = scrollContainerRef.current;
        if (container) {
          const isAtEnd = container.scrollLeft + container.clientWidth >= container.scrollWidth;
          if (isAtEnd) {
            container.scrollLeft = 0;
          } else {
            container.scrollLeft += 1;
          }
        }
      }, 30);
    }

    return () => clearInterval(scrollInterval);
  }, [autoScroll, isHovered]);

  return (
    <div className="relative">
      <div 
        ref={scrollContainerRef}
        className="flex space-x-4 sm:space-x-6 overflow-x-auto snap-x snap-mandatory hide-scrollbar py-2 px-4 sm:px-6"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {aiTools.map((tool, index) => (
          <AIToolCard key={index} tool={tool} index={index} />
        ))}
      </div>
      
      {/* Adjust gradient widths for mobile */}
      <div className="absolute left-0 top-0 bottom-0 w-8 sm:w-12 bg-gradient-to-r from-gray-50 dark:from-gray-900 to-transparent pointer-events-none" />
      <div className="absolute right-0 top-0 bottom-0 w-8 sm:w-12 bg-gradient-to-l from-gray-50 dark:from-gray-900 to-transparent pointer-events-none" />
    </div>
  );
};

const HomePage = () => {
  // Add this scroll animation hook
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 300], [0, -50]);

  // Add state for auto-scrolling
  const [isLoading, setIsLoading] = useState(true);

  // Auto-scroll effect
  useEffect(() => {
    // Only handle initial loading
    const loadTimer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loadTimer);
  }, [isLoading]); // Empty dependency array means this only runs once on mount

  // Scroll helper function


  return (
    <div className="min-h-screen relative overflow-hidden bg-gray-50 dark:bg-gradient-to-br dark:from-gray-900 dark:via-blue-950 dark:to-indigo-950 transition-all duration-300">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <motion.div
          className="absolute inset-0 opacity-10"
          animate={{
            backgroundPosition: ["0% 0%", "100% 100%"],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: "reverse",
          }}
          style={{
            backgroundImage: "url('/grid-pattern.svg')",
            backgroundSize: "30px 30px",
          }}
        />
        {/* Add floating orbs */}
        <div className="absolute inset-0">
          {[...Array(3)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute w-72 h-72 bg-gradient-to-r from-blue-500/30 to-purple-500/30 rounded-full blur-3xl"
              animate={{
                x: ["0%", "100%", "0%"],
                y: ["0%", "50%", "0%"],
              }}
              transition={{
                duration: 10 + i * 2,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
              }}
              style={{
                left: `${i * 30}%`,
                top: `${i * 20}%`,
              }}
            />
          ))}
        </div>
      </div>

      {/* Hero Content */}
      <motion.section 
        style={{ y }}
        className="relative pt-8 pb-24 px-4 sm:px-6 lg:px-8"
      >
        <div className="max-w-6xl mx-auto space-y-8">
          {/* Trust Badges - Side by Side on Mobile */}
          <div className="flex justify-center gap-3 px-4">
            <motion.div 
              className="flex-1 max-w-[160px] inline-flex items-center justify-center px-3 py-1.5 relative backdrop-blur-sm"
              whileHover={{ scale: 1.05 }}
            >
              <div className="absolute inset-0 bg-blue-500/10 dark:bg-blue-400/10 rounded-full blur-xl" />
              <Users className="w-4 h-4 mr-2 text-blue-600 dark:text-blue-400" />
              <span className="text-xs font-medium text-blue-600 dark:text-blue-400 whitespace-nowrap">
                500+ Businesses
              </span>
            </motion.div>
            
            <motion.div 
              className="flex-1 max-w-[160px] inline-flex items-center justify-center px-3 py-1.5 relative backdrop-blur-sm"
              whileHover={{ scale: 1.05 }}
            >
              <div className="absolute inset-0 bg-green-500/10 dark:bg-green-400/10 rounded-full blur-xl" />
              <Shield className="w-4 h-4 mr-2 text-green-600 dark:text-green-400" />
              <span className="text-xs font-medium text-green-600 dark:text-green-400 whitespace-nowrap">
                Enterprise Security
              </span>
            </motion.div>
          </div>

          {/* Three-Line Headline with Progressive Reveal */}
          <div className="space-y-4 text-center">
            <motion.h1 
              className="text-4xl md:text-6xl lg:text-7xl font-bold tracking-tight leading-none"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <motion.div className="mb-2">Transform Your</motion.div>
              <motion.div 
                className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-2"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                Business Operations
              </motion.div>
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                With AI Power
              </motion.div>
            </motion.h1>
          </div>

          {/* Enhanced Type Animation Container */}
          <motion.div 
            className="text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6 }}
          >
            <div className="min-h-[60px] flex items-center justify-center">
              <TypeAnimation
                sequence={[
                  "Automate Customer Service",
                  1500,
                  "Generate AI Images",
                  1500,
                  "Streamline Operations",
                  1500,
                ]}
                wrapper="span"
                speed={50}
                repeat={Infinity}
                className="text-2xl md:text-3xl text-gray-600 dark:text-gray-300 font-light inline-block"
              />
            </div>
          </motion.div>

          {/* Simplified Value Metrics */}
          <motion.div 
            className="flex flex-wrap justify-center gap-8 md:gap-16 mt-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
          >
            {[
              { number: "500+", label: "Active Businesses" },
              { number: "24/7", label: "AI Support" },
              { number: "95%", label: "Satisfaction Rate" }
            ].map((stat, index) => (
              <motion.div 
                key={index}
                className="text-center"
                whileHover={{ y: -5 }}
              >
                <div className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent mb-1">
                  {stat.number}
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  {stat.label}
                </div>
              </motion.div>
            ))}
          </motion.div>

          {/* Enhanced CTA Section */}
          <div className="flex flex-col sm:flex-row gap-6 justify-center items-center">
            <motion.button
              whileHover={{ scale: 1.05, boxShadow: "0 0 25px rgba(37, 99, 235, 0.5)" }}
              whileTap={{ scale: 0.95 }}
              className="group relative w-full sm:w-auto overflow-hidden px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-xl text-lg font-medium"
            >
              <span className="relative z-10 flex items-center justify-center">
                Start Free Trial
                <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </span>
              <span className="absolute bottom-0 left-0 h-1 bg-white/20 transition-all duration-300 w-0 group-hover:w-full" />
            </motion.button>
            
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group w-full sm:w-auto px-8 py-4 border-2 border-gray-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-xl hover:border-blue-600 hover:text-blue-600 transition-all duration-300 text-lg font-medium"
            >
              Schedule Demo
              <Play className="inline-block ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </motion.button>
          </div>
        </div>
      </motion.section>

      {/* Visual connection between sections */}
      <div className="relative h-12 mb-8">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-blue-50/20 to-transparent dark:via-blue-900/10" />
      </div>

      {/* Core Services Section */}
      <section className="relative px-6 py-16 md:px-12 lg:px-24">
        {/* Simplified gradient background */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-blue-50/20 to-transparent dark:via-blue-900/10" />

        <div className="max-w-7xl mx-auto">
          {/* Section Header - Simplified animation */}
          <div className="text-center mb-16">
            <div className="inline-flex items-center justify-center p-2 bg-blue-50 dark:bg-blue-900/30 rounded-xl mb-4">
              <Cpu className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-2" />
              <span className="text-sm font-medium text-blue-600 dark:text-blue-400">
                AI-Powered Solutions
              </span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-800 dark:text-white mb-4">
              Core Services
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
              Transform your business with our advanced AI solutions
            </p>
          </div>

          {/* Services Grid - Optimized for performance */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              {
                icon: MessageSquare,
                title: "AI Chatbots",
                description: "Customer service with natural language chatbots.",
                features: ["24/7 Customer Support", "Multi-language Support", "Custom Training"]
              },
              {
                icon: Cpu,
                title: "AI Automation",
                description: "Streamline operations with intelligent automation.",
                features: ["Workflow Automation", "Data Processing", "Smart Integrations"]
              },
              {
                icon: Code,
                title: "Custom AI Development",
                description: "Tailored AI solutions for your unique needs.",
                features: ["Custom Models", "API Integration", "Scalable Solutions"]
              }
            ].map((service, index) => (
              <div
                key={index}
                className="group bg-white dark:bg-gray-800/80 rounded-2xl p-6 shadow-sm hover:shadow-md transition-shadow duration-200"
              >
                {/* Icon */}
                <div className="w-12 h-12 rounded-xl flex items-center justify-center bg-blue-50 dark:bg-blue-900/30 mb-6">
                  <service.icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                </div>

                {/* Content */}
                <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-3">
                  {service.title}
                </h3>
                
                <p className="text-gray-600 dark:text-gray-300 mb-6">
                  {service.description}
                </p>

                {/* Features */}
                <ul className="space-y-3 mb-6">
                  {service.features.map((feature, idx) => (
                    <li
                      key={idx}
                      className="flex items-center text-gray-600 dark:text-gray-300"
                    >
                      <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                      {feature}
                    </li>
                  ))}
                </ul>

                {/* Button */}
                <button className="w-full px-4 py-2 text-blue-600 dark:text-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/30 rounded-xl transition-colors duration-200 font-medium flex items-center justify-center">
                  Learn More
                  <ChevronRight className="w-4 h-4 ml-1" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* AI Tools Section */}
      <section className="relative py-8">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <SectionHeader />
          <AIToolsSection />
        </div>
      </section>

      {/* Why Choose Us Section */}
      <motion.section 
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className="relative px-6 py-16 md:px-12 lg:px-24"
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-blue-50/30 to-transparent dark:via-blue-900/10 pointer-events-none" />
        <div className="max-w-7xl mx-auto">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <motion.h2 
              className="text-3xl md:text-4xl font-bold text-gray-800 dark:text-white mb-4"
            >
              Why Choose Us
            </motion.h2>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              We are committed to providing the best AI solutions for your business.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Georgian Market Expertise */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="group bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-2xl p-6 
                         shadow-lg hover:shadow-xl transition-all"
            >
              <div className="bg-blue-100 dark:bg-blue-900/50 p-4 rounded-xl mb-4 w-fit">
                <Building2 className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">
                Georgian Market Expertise
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                We have extensive experience in the Georgian market.
              </p>
            </motion.div>

            {/* Cutting-Edge AI */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="group bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-2xl p-6 
                         shadow-lg hover:shadow-xl transition-all"
            >
              <div className="bg-blue-100 dark:bg-blue-900/50 p-4 rounded-xl mb-4 w-fit">
                <Lightbulb className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">
                Cutting-Edge AI
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                We use the latest AI technologies to provide the best solutions.
              </p>
            </motion.div>

            {/* Growth Support */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.5 }}
              className="group bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-2xl p-6 
                         shadow-lg hover:shadow-xl transition-all"
            >
              <div className="bg-blue-100 dark:bg-blue-900/50 p-4 rounded-xl mb-4 w-fit">
                <TrendingUp className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              </div>
              <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">
                Growth Support
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                We provide ongoing support to help your business grow.
              </p>
            </motion.div>
          </div>
        </div>
      </motion.section>

      {/* CTA Section */}
      

      {/* Add Footer here */}
      <Footer />

      {/* Add the AzriCharacter component */}
    </div>
  );
};

export default HomePage;